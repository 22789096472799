import { Reducer } from 'redux';
import ActionTypes, { TBookmarksState } from './types';

export const initialState: TBookmarksState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TBookmarksState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BOOKMARKS_R:
    case ActionTypes.GET_BOOKMARKSCSV_R:
      return { ...state, loading: true, errors: '' };
    
    case ActionTypes.GET_BOOKMARKSCSV_E:
    case ActionTypes.GET_BOOKMARKS_E:
      return { ...state, loading: false, errors: action.payload };
    
      case ActionTypes.GET_BOOKMARKSCSV_S:
    case ActionTypes.GET_BOOKMARKS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export { reducer as BookmarksReducer };
