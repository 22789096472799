import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppStore} from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import {StylesTable} from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import {IProduct} from '../../store/products/types';
import {EnhancedTableHead} from '../ui';
import {Order, stableSort} from '../../utils/stableSort';
import {GetScholl, GetSchollCsv} from '../../store/scholl/actions';
import Button from '@mui/material/Button';
import {BasicDatePicker, SelectOutline, SelectSearchReq} from '../ui';
import {useSearchProducts} from '../../hooks/useSearchProducts';
import {IDataOpt} from '../ui/SelectSearchReq';
import moment from 'moment';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import {IScholl} from "../../store/scholl/types";
import {EditRow} from "./EditRow";
import CircularProgress from '@mui/material/CircularProgress';

interface Column {
  id: 'user_id' | 'fio' | 'ukd' | 'UkdDateStart' | 'UkdDateEnd' | 'ProductVersionName'
    | 'fioCertificate' | 'changedFioCertificate' | 'DateTest' | 'ProgramTypeint' | 'Printed' | 'DataNP';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
  minWidth?: string;
}

const columns: Column[] = [
  {id: 'user_id', label: 'Userid', align: 'center', sort: true, width: '5%'},
  {id: 'fio', label: 'ПІБ', align: 'center', sort: true, width: '7%'},
  {id: 'ukd', label: 'Код УКД', align: 'center', sort: true, width: '10%', minWidth: '160px'},
  {id: 'UkdDateStart', label: 'Дата старту УКД', align: 'center', sort: true, width: '5%'},
  {id: 'UkdDateEnd', label: 'Дата закінчення УКД', align: 'center', sort: true, width: '5%'},
  {id: 'ProductVersionName', label: 'Назва версії', align: 'center', sort: true, width: '10%'},
  {id: 'fioCertificate', label: 'ПІБ для сертифіката', align: 'center', sort: true, width: '7%'},
  {id: 'changedFioCertificate', label: 'Змінений ПІБ для сертифіката', align: 'center', sort: true, width: '7%'},
  {id: 'DataNP', label: 'Дані нової пошти', align: 'center', sort: true, width: '7%'},
  {id: 'DateTest', label: 'Дата здачі підсумкового тесту', align: 'center', sort: true, width: '5%'},
  {id: 'ProgramTypeint', label: 'Вид сертифіката', align: 'center', sort: true, width: '5%'},
  {id: 'Printed', label: 'Потрібен друк. сертифікат', align: 'center', sort: true, width: '5%'},
];


type PropsType = {};

export const ListSchool: React.FC<PropsType> = () => {
  const [userId, setUserId] = React.useState('');
  const [fio, setFio] = React.useState('');
  const [ukd, setUkd] = React.useState('');
  const [ukdDateStart, setUkdDateStart] = React.useState<Date | null>(null);
  const [ukdDateEnd, setUkdDateEnd] = React.useState<Date | null>(null);
  const [productVersionName, setProductVersionName] = React.useState('');
  const [fioCertificate, setFioCertificate] = React.useState('');
  const [dateTest, setDateTest] = React.useState<Date | null>(null);
  const [programType, setProgramType] = React.useState('');
  const [printed, setPrinted] = React.useState(''); // Assuming this is a boolean state
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');
  const [productMain, setProductMain] = React.useState<IDataOpt | null>(null);
  const [productId, setProductId] = React.useState('');
  const [editData, setEditData] = React.useState<IScholl | null>(null);
  const {Scholl} = useSelector((store: AppStore) => store);
  const [isNPDataForm, setIsNPDataForm] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const {subOptions, mainOptions, selectedMainProduct, handleSearch: handleSearchProduct} = useSearchProducts();
  const getSchoolRequest = React.useCallback(() => {
    dispatch(
      GetScholl.request({
        data: {
          userId: userId, // Updated to new state variable
          fio, // Updated to new state variable
          ukd, // Added this new state variable
          ukdDateStart: ukdDateStart, // Assuming you need to convert date to ISO string
          ukdDateEnd: ukdDateEnd,  // Same assumption as above
          productVersionName: productVersionName, // Updated to new state variable
          fioCertificate: fioCertificate, // Updated to new state variable
          dateTest: dateTest, // Assuming conversion to ISO string
          programType: programType, // Updated to new state variable
          printed: printed, // Updated to new state variable
          productMainId: productMain ? String(productMain.id) : '',
          productId

        },
      })
    );
  }, [
    dispatch,
    userId,
    fio,
    ukd,
    ukdDateStart,
    ukdDateEnd,
    productVersionName,
    fioCertificate,
    dateTest,
    programType,
    printed,
    productMain,
    productId
  ])

  React.useEffect(() => {
    getSchoolRequest();
  }, [
    getSchoolRequest
  ]);
  const handleChangePage = (event: unknown, newPage: number) => {
    if (!Scholl.data) return null;
    if (Scholl.data.pages >= newPage) {
      dispatch(
        GetScholl.request({
          data: {
            userId: userId,
            fio,
            ukd,
            ukdDateStart: ukdDateStart,
            ukdDateEnd: ukdDateEnd,
            productVersionName: productVersionName,
            fioCertificate: fioCertificate,
            dateTest: dateTest, // updated to newPage
            programType: programType,
            printed: printed, // assuming this needs to be sent
            productMainId: productMain ? String(productMain.id) : '',
            productId,
            page: newPage
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setProductMain({id: 0, label: ''});
    setProductId('');
    setUserId('');
    setFio('');
    setUkd('');
    setUkdDateStart(null);
    setUkdDateEnd(null);
    setProductVersionName('');
    setFioCertificate('');
    setDateTest(null);
    setProgramType('');
    setPrinted(''); // assuming this is a boolean
    dispatch(GetScholl.request({data: {}}));
  };


  const exportFile = () => {
    dispatch(
      GetSchollCsv.request({
        data: {
          userId: userId,
          fio,
          ukd,
          ukdDateStart: ukdDateStart ? ukdDateStart : '',
          ukdDateEnd: ukdDateEnd ? ukdDateEnd : '',
          productVersionName: productVersionName,
          fioCertificate: fioCertificate,
          dateTest: dateTest, // updated to newPage
          programType: programType,
          printed: printed, // assuming this needs to be sent
          productMainId: productMain ? String(productMain.id) : '',
          productId
        },
      })
    );
  };

  const handleCancel = () => {
    setEditData(null);
    setIsNPDataForm(false);
    getSchoolRequest();
  };

  const calculateDiff = (dateEnd?: string | undefined, countOfAvailableDays: number = 0) => {
    if (!dateEnd) return false;
    const dateEndMoment = moment(dateEnd);
    const dateNow = moment();
    const diff = dateNow.diff(dateEndMoment, 'days');
    return diff <= countOfAvailableDays;
  };

  const defineCountOfDaysAndDateEnd = (row: IScholl) => {
    const result = {
      countOfAvailableDays: 60,
      dateEnd: row.dateEnd
    } as {
      countOfAvailableDays: number,
      dateEnd: string
    };

    if (row.appId === 2511 || row.version_product === '50015') {
      result.countOfAvailableDays = 16
    } else if (row.version_product === '50068') {
      const dateEndPlusThreeMonth = moment(row.dateStart).add(3, 'months');
      result.countOfAvailableDays = dateEndPlusThreeMonth.diff(row.dateStart, 'days');
      result.dateEnd = row.dateStart
    } else if (row.version_product === '50046' || row.version_product === '50003') {
      const dateEndPlusThreeMonth = moment(row.dateStart).add(4, 'months');
      result.countOfAvailableDays = dateEndPlusThreeMonth.diff(row.dateStart, 'days');
      result.dateEnd = row.dateStart
    }

    return result
  };

  const isAvailableEditMode = (row: IScholl) => {
    const {countOfAvailableDays, dateEnd} = defineCountOfDaysAndDateEnd(row);
    return calculateDiff(dateEnd, countOfAvailableDays);
  }
  if (editData) {
    return <EditRow data={editData} handleCancel={handleCancel} isNPDataForm={isNPDataForm}/>;
  }
  return (
    <>
      <Box style={{display: 'flex', flexWrap: 'wrap'}}>
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="Userid"
          variant="outlined"
          value={userId}
          className={classesTable.searchField}
          onChange={(e) => {
            setUserId(e.target.value);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="ПІБ"
          variant="outlined"
          value={fio}
          className={classesTable.searchField}
          onChange={(e) => {
            setFio(e.target.value);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="ПІБ для сертифіката"
          variant="outlined"
          value={fioCertificate}
          className={classesTable.searchField}
          onChange={(e) => {
            setFioCertificate(e.target.value);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="УКД"
          variant="outlined"
          value={ukd}
          className={classesTable.searchField}
          onChange={(e) => {
            setUkd(e.target.value);
          }}
        />
        <div className={classesTable.searchField} style={{width: '210px'}}>
          <BasicDatePicker id="ukdDateStart" label="Дата старту" value={ukdDateStart} onChange={setUkdDateStart}
          />
        </div>
        <div className={classesTable.searchField} style={{width: '210px'}}>
          <BasicDatePicker id="dateEnd" label="Дата закінчення" value={ukdDateEnd} onChange={setUkdDateEnd}/>
        </div>
        <div className={classesTable.searchField} style={{width: '210px'}}>
          <BasicDatePicker id="dateTest" label="Дата здачі тесту" value={dateTest} onChange={setDateTest}/>
        </div>
        <SelectSearchReq
          data={productMain}
          style={{marginRight: '10px', width: '210px'}}
          title="Головний продукт"
          className={classesTable.searchField}
          onChange={(data) => {
            setProductId('');
            selectedMainProduct(data);
            setProductMain(data);
          }}
          options={mainOptions}
          handleRequest={handleSearchProduct}
        />
        <SelectOutline
          style={{marginRight: '10px', width: '210px'}}
          id="outlined-basic"
          title="Версія продукту"
          value={productId}
          className={classesTable.searchField}
          options={subOptions}
          handleChange={setProductId}
        />
        <SelectOutline
          style={{width: '210px'}}
          className={classesTable.searchField}
          title="Потрібен друкований сертифікат"
          options={[
            {value: '3', label: 'Усі'},
            {value: '0', label: 'Так'},
            {value: '1', label: 'Ні'},
          ]}
          handleChange={(value) => setPrinted(value)}
          value={printed}
        />
        <SelectOutline
          style={{width: '210px'}}
          className={classesTable.searchField}
          title="Вид сертифіката"
          options={[
            {value: '3', label: 'Усі'},
            {value: '1', label: 'Друкований'},
            {value: '0', label: 'Електронний'},
          ]}
          handleChange={(value) => setProgramType(value)}
          value={programType}
        />
        <Button variant="contained" color="primary" onClick={clearFilters} style={{height: '40px'}}>
          Очистити фільтри
        </Button>
        <Button variant="contained" color="primary" onClick={exportFile} style={{height: '40px', marginLeft: '10px'}}>
          Вивантажити у XLSX
        </Button>
      </Box>
      {Scholl.loading ?
        <CircularProgress
          style={{position: 'absolute', top: '50%', left: '50%', marginTop: '-20px', marginLeft: '-20px'}}
        />
        : <>
          <TableContainer className={classesTable.container}>
            <Table className={classesTable.table} stickyHeader aria-label="sticky table" style={{width: 'auto'}}>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns}/>
              <TableBody>
                {Scholl && Scholl.data &&
                  stableSort(Scholl.data.scholl, order, orderBy).map((row, i) => {
                    return (
                      <TableRow style={{maxHeight: 50}} hover role="checkbox" tabIndex={-1} key={`${i}-${row.userId}`}>
                        <TableCell align="center">
                          {row.userId}
                        </TableCell>
                        <TableCell align="center">
                          {`${row.lname} ${row.fname} ${row.mname}`}
                        </TableCell>
                        <TableCell align="center">
                          {row.ukd}
                        </TableCell>
                        <TableCell align="center" style={{overflowWrap: 'break-word'}}>
                          {moment(row.dateStart).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="center" style={{overflowWrap: 'break-word'}}>
                          {moment(row.dateEnd).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          {row.version_product_name}
                        </TableCell>
                        <TableCell align="center">
                          {`${row.second_name} ${row.first_name} ${row.middle_name}`}
                        </TableCell>
                        <TableCell align="center" style={{position: 'relative'}}>
                          {isAvailableEditMode(row) &&
														<IconButton
															aria-label="edit"
															onClick={() => setEditData(row)}
														>
															<EditIcon
																fontSize="small"
															/>
														</IconButton>}
                          <br/>
                          {`${row?.changed_second_name || ''} ${row?.changed_first_name || ''} ${row?.changed_middle_name || ''}`}
                          <span style={{fontStyle: 'italic', fontSize: '0.8em'}}>
                          <br/>
                            {row.changed_by ? 'Змінив: ' + row.changed_by : ''}
                        </span>
                        </TableCell>
                        <TableCell align="center" style={{position: 'relative'}}>
                          {(isAvailableEditMode(row) && !row?.no_print) &&
														<IconButton
															aria-label="edit"
															onClick={() => {
                                setEditData(row);
                                setIsNPDataForm(true);
                              }}
														>
															<EditIcon
																fontSize="small"
															/>
														</IconButton>}
                          <br/>
                          <span style={{fontStyle: 'italic', fontSize: '0.8em'}}>
                          <br/>
                        </span>
                        </TableCell>
                        <TableCell align="center">
                          {row.date_test ? moment(row.date_test).format('DD-MM-YYYY') : ''}
                        </TableCell>
                        <TableCell align="center">
                          {row.printed}
                        </TableCell>
                        <TableCell align="center">
                          {row.printed === '0' ? 'Ні' : row.no_print ? 'Ні' : 'Так'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classesTable.pagination}
            count={Scholl && Scholl.data ? Scholl.data.pages : 0}
            page={Scholl && Scholl.data ? Scholl.data.currentPage : 1}
            onChange={handleChangePage}
          />
        </>}
    </>
  );
};
