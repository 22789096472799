import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { IUser } from '../../store/users/types';
import { StylesTable } from '../Stylestable';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { mergeFio } from '../../utils/mergeFio';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import { AutologinModal } from '../modals';
import { collectionStatus } from '../../utils/consts';

interface Column {
  id: 'id' | 'name' | 'ukd' | 'dateStart' | 'dateEnd' | 'status' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  sort?: boolean;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'id', label: 'Код', align: 'left', sort, width: '80px' },
  { id: 'name', label: 'Назва продукту', align: 'left', sort },
  { id: 'ukd', label: 'УКД', align: 'left', sort },
  { id: 'dateStart', label: 'Дата старту', align: 'left', width: '15%', sort },
  { id: 'dateEnd', label: 'Дата закінчення', align: 'left', width: '15%', sort },
  { id: 'status', label: 'Статус', align: 'left', width: '10%', sort },
];

interface PropsType {
  data: IUser;
  handleCancel: () => void;
}

export const UserSubscriptions: React.FC<PropsType> = ({ data, handleCancel }) => {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IUser>('absnum');
  const [isAutologin, setIsAutologin] = React.useState<IUser | null>(null);

  const { Auth } = useSelector((store: AppStore) => store);

  const classesTable = StylesTable();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUser) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Box>
        <Typography component="h1" variant="h5" style={{ margin: '0 0 20px' }}>
          Продукти клієнта: {`№ ${data.absnum}, ${mergeFio({ l: data.lname, f: data.fname, m: data.mname })}, ${data.email}`}
        </Typography>
        <Button className={classesTable.searchField} style={{ marginLeft: '20px' }} variant="outlined" color="primary" onClick={handleCancel}>
          Повернутися
        </Button>
        <Button
          className={classesTable.searchField}
          variant="contained"
          color="primary"
          style={{ marginLeft: '20px' }}
          onClick={() => {
            setIsAutologin(data);
          }}
        >
          Увійти як клієнт
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} columns={columns} rights={Auth.data?.rights} onRequestSort={handleRequestSort} />
          <TableBody>
            {data &&
              stableSort(data.subscriptions, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.id}`}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.ukd}</TableCell>
                    <TableCell className={classesTable.row}>{moment(row.dateStart).format('DD.MM.YYYY')}</TableCell>
                    <TableCell className={classesTable.row}>{moment(row.dateEnd).format('DD.MM.YYYY')}</TableCell>
                    <TableCell className={classesTable.row} style={{ color: collectionStatus[row.status]?.color || '' }}>
                      {collectionStatus[row.status]?.name || ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isAutologin ? (
        <AutologinModal
          handleClose={() => {
            setIsAutologin(null);
          }}
          data={isAutologin}
          open={!!isAutologin}
        />
      ) : null}
    </>
  );
};
