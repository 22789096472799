import { makeStyles } from '@mui/styles';

export const Styles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '900px',
  },
  wrappField: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px',
    border: 'solid 1px silver',
    borderRadius: '4px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      borderColor: 'black',
    },
  },
  wrappFieldFocus: {
    '&:hover': {
      borderColor: '#1976d2',
    },
    border: 'solid 1px #1976d2',
    boxShadow: 'inset 1px 1px #1976d2, inset -1px -1px #1976d2',
  },
  field: {
    padding: '8.5px 14px',
    width: '97%',
    fontSize: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    width: '3%',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.54)',
    justifyContent: 'flex-end',
    paddingRight: '10px',
  },
  iconFocus: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  placeholder: {
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    top: '9px',
    left: '8px',
    padding: '0 6px',
    background: 'white',
    transition: 'all 200ms ease-out',
  },
  placeholderFocus: {
    color: '#1976d2',
    transform: 'translate(-10%, -80%) scale(0.75)',
  },
  placeholderInit: {
    transform: 'translate(-10%, -80%) scale(0.75)',
  },
  select: {
    width: '100%',
    maxHeight: '300px',
    overflow: 'auto',
    position: 'absolute',
    background: 'white',
    zIndex: 10,
    opacity: '1',
    transform: 'none',
    minWidth: '250px',
    transition: 'opacity 303ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 202ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transformOrigin: '200px 0px',
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
  list: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    position: 'relative',
    paddingTop: '8px',
    paddingBottom: '8px',
    outline: '0',
  },
  item: {
    lineHeight: '48px',
    listStyle: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: '#fff',
  },
  wrappSubItem: {
    display: 'flex',
    width: '100%',
    fontWeight: 'bold',
  },
  subItem: {
    width: '95%',
  },
  input: {
    width: '-webkit-fill-available',
    height: '100%',
    border: 'none',
    outline: 'none',
    fontSize: '16px',
  },
  wrapHint: {
    maxWidth: '300px',
  },
  wrapLink: {
    color: ' -webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
