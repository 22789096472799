import React from 'react';
import { useDispatch } from 'react-redux';
import { ChangePasswd } from '../../store/users/actions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { collectionValidate } from '../../utils/collectionValidate';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle } from '@mui/lab';
import bcrypt from 'bcryptjs';
import Typography from '@mui/material/Typography';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

interface PropsType {
  userId: number;
  handleClose?: () => void;
  url: string;
}

const salt = bcrypt.genSaltSync(10);

export const FieldNewPasswd: React.FC<PropsType> = ({ userId, handleClose, url }) => {
  const [isNewPassw, setIsNewPassw] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleChangePasswd = () => {
    if (!password) return setError('Обов’язкове поле');
    if (!collectionValidate['password'].checkValue(password)) {
      return setError(collectionValidate['password'].error);
    }
    const hashedPassword = bcrypt.hashSync(password, salt);
    setLoading(true);
    dispatch(
      ChangePasswd.request({
        url : url,
        data: {
          id: userId,
          password: hashedPassword,
        },
        callBack: (success) => {
          if (!success) setError('Сталася помилка');
          if (success) {
            setIsNewPassw(false);
          }
          setLoading(false);
        },
      })
    );
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  if (!isNewPassw) {
    return (
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <Button variant="text" onClick={() => setIsNewPassw(true)}>
          Змінити пароль
        </Button>
        {password ? (
          <>
            {' '}
            <Typography color={'gray'} pl={2}>
              {password}
            </Typography>
            <Button onClick={() => handleCopy(password)} startIcon={<ContentCopyOutlinedIcon />} />
          </>
        ) : null}
      </Box>
    );
  }

  return (
    <>
      <Box style={{ display: 'flex', width: '900px', marginTop: '20px' }}>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic-password"
          label="Пароль"
          variant="outlined"
          value={password}
          onChange={(event) => {
            if (!collectionValidate['password'].checkValue(event.target.value)) {
              setError(collectionValidate['password'].error);
            } else {
              setError('');
            }
            setPassword(event.target.value);
          }}
        />
        <Button variant="contained" color="primary" disabled={loading} onClick={handleChangePasswd} style={{ marginLeft: '30px' }}>
          {loading ? <CircularProgress size={15} /> : 'Зберегти'}
        </Button>
      </Box>
      {error ? (
        <Alert style={{ marginTop: '10px' }} severity="error">
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      ) : null}
    </>
  );
};
