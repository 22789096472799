import { Reducer } from 'redux';
import ActionTypes, { TSubscriptionsState } from './types';

export const initialState: TSubscriptionsState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TSubscriptionsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUBSCRIPTIONS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_SUBSCRIPTIONS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_SUBSCRIPTIONS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ActionTypes.UPDATE_SUBSCRIPTION_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              subscriptions: state.data.subscriptions.map((it) => {
                if (it.code === action.payload.code) {
                  return action.payload;
                }
                return it;
              }),
            }
          : state.data,
      };

    default:
      return state;
  }
};

export { reducer as SubscriptionsReducer };
