export interface TUsersState {
  data: { users: IUser[]; currentPage: number; pages: number } | null;
  editData: { type: 'edit' | 'new'; data?: IUser | null } | null;
  userSubs: IUser | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IUser {
  absnum: number;
  email: string;
  passwd: string;
  lname: string;
  fname: string;
  mname: string;
  bday: Date;
  position_id: number;
  company: string;
  city: string;
  phone: string;
  gender?: number;
  approved: number;
  isstart: number;
  updateDate: Date;
  subscriptions: IUserSubscription[];
}

export interface IUserSubscription {
  id: number;
  ukd: string;
  name: string;
  parentId: number;
  userId: number;
  status: number;
  dateStart: Date;
  dateEnd: Date;
  createDate: Date;
}

enum ActionTypes {
  SET_SUBSCRIPTIONS = '@@users/SET_SUBSCRIPTIONS',
  SET_EDIT_DATA = '@@users/SET_EDIT_DATA',
  SET_USER_SUBSCRIPTIONS = '@@users/SET_USER_SUBSCRIPTIONS',

  GET_USERS_R = '@@users/GET_USERS_R',
  GET_USERS_S = '@@users/GET_USERS_S',
  GET_USERS_E = '@@users/GET_USERS_E',

  GET_USER_R = '@@users/GET_USER_R',
  GET_USER_S = '@@users/GET_USER_S',
  GET_USER_E = '@@users/GET_USER_E',

  SEARCH_USERS_R = '@@users/SEARCH_USERS_R',
  SEARCH_USERS_S = '@@users/SEARCH_USERS_S',
  SEARCH_USERS_E = '@@users/SEARCH_USERS_E',

  UPDATE_USER_R = '@@users/UPDATE_USER_R',
  UPDATE_USER_S = '@@users/UPDATE_USER_S',
  UPDATE_USER_E = '@@users/UPDATE_USER_E',

  DELETE_USER_R = '@@users/DELETE_USER_R',
  DELETE_USER_S = '@@users/DELETE_USER_S',
  DELETE_USER_E = '@@users/DELETE_USER_E',

  CHANGE_PASSWORD_R = '@@users/CHANGE_PASSWORD_R',
  CHANGE_PASSWORD_S = '@@users/CHANGE_PASSWORD_S',
  CHANGE_PASSWORD_E = '@@users/CHANGE_PASSWORD_E',

  SEND_EMAIL_CONFIRM_R = '@@users/SEND_EMAIL_CONFIRM_R',
  SEND_EMAIL_CONFIRM_S = '@@users/SEND_EMAIL_CONFIRM_S',
  SEND_EMAIL_CONFIRM_E = '@@users/SEND_EMAIL_CONFIRM_E',

  EMAIL_CONFIRM_R = '@@users/EMAIL_CONFIRM_R',
  EMAIL_CONFIRM_S = '@@users/EMAIL_CONFIRM_S',
  EMAIL_CONFIRM_E = '@@users/EMAIL_CONFIRM_E',

  AUTOLOGIN_R = '@@users/AUTOLOGIN_R',
  AUTOLOGIN_S = '@@users/AUTOLOGIN_S',
  AUTOLOGIN_E = '@@users/AUTOLOGIN_E',

  AUTOLOGIN_BY_PRODUCT_R = '@@users/AUTOLOGIN_BY_PRODUCT_R',
  AUTOLOGIN_BY_PRODUCT_S = '@@users/AUTOLOGIN_BY_PRODUCT_S',
  AUTOLOGIN_BY_PRODUCT_E = '@@users/AUTOLOGIN_BY_PRODUCT_E',
}

export default ActionTypes;
