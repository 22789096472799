import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { StylesEdit } from '../../StylesEdit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ISupportQuest } from '../../../store/support/types';
import TextField from '@mui/material/TextField';
import { CreateSupportQuest, UpdateSupportQuest } from '../../../store/support/actions';
import { EditorDefault } from '../../Editors';

interface PropsType {
  type: 'edit' | 'new';
  position: number;
  partId: number;
  subpartId: number;
  data?: ISupportQuest | null;
  handleCancel: () => void;
}

export const EditSupportQuest: React.FC<PropsType> = ({ type, position, partId, subpartId, data, handleCancel }) => {
  const [name, setName] = React.useState(data?.name || '');
  const [approved, setApproved] = React.useState(data ? data.approved : 1);
  const [error, setError] = React.useState(false);

  const editorDefRef = React.useRef<any | null>(null);

  const { Support } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const classes = StylesEdit();

  const onSubmit = () => {
    const body = editorDefRef.current?.getContent();
    if (!name || !body) return setError(true);
    setError(false);
    const callBack = (succ: boolean) => {
      if (succ) {
        handleCancel();
      }
    };
    if (type === 'new') {
      dispatch(
        CreateSupportQuest.request({
          data: {
            name,
            approved,
            position,
            partId,
            subpartId,
            body,
          },
          callBack,
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateSupportQuest.request({
          id: data.id,
          data: {
            name,
            approved,
            position,
            partId,
            subpartId,
            body,
          },
          callBack,
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component="h1" variant="h5">
            {type === 'new' ? 'Створити питання' : 'Редагувати питання'}
          </Typography>
        </Box>
        <FormControlLabel
          style={{ marginTop: '20px' }}
          control={<Checkbox checked={!!approved} />}
          onChange={() => setApproved(approved ? 0 : 1)}
          label="Публікувати"
        />
        <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic-name"
          label="Питання"
          variant="outlined"
          value={name}
          error={error && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder="Відповідь" editorRef={editorDefRef} initialValue={data?.body} />
        </div>
        <Box style={{ margin: '20px 0' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Support.loading}
            onClick={onSubmit}
            style={{ height: '36px', marginRight: '40px' }}
          >
            {Support.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Support.errors ? (
          <Alert severity="error">
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
