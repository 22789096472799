import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { ISubscription, TSubscriptionsState } from './types';

const GetSubscriptionsAction = new Actions('GET_SUBSCRIPTIONS', ActionTypes);
export interface TypeGetSubscriptionsR extends TDefRequest {
  data: {
    ukd?: string;
    dateStart?: Date | null;
    dateEnd?: Date | null;
    status?: string;
    typeSubscribe?: string;
    page?: number;
    productId?: string;
    productMainId?: string;
    isActiveUkd?: boolean;
  };
}
export const GetSubscriptions = {
  request: (payload: TypeGetSubscriptionsR) => GetSubscriptionsAction.request(payload),
  success: (payload: TSubscriptionsState['data']) => GetSubscriptionsAction.success(payload || {}),
  error: (message: string) => GetSubscriptionsAction.error(message),
};

const UpdateSubscriptionAction = new Actions('UPDATE_SUBSCRIPTION', ActionTypes);
export interface TypeUpdateSubscriptionR extends TDefRequest {
  data: {
    ukd: string;
    userId: number;
    status: number;
  };
}
export const UpdateSubscription = {
  request: (payload: TypeUpdateSubscriptionR) => UpdateSubscriptionAction.request(payload),
  success: (payload: ISubscription) => UpdateSubscriptionAction.success(payload || {}),
  error: (message: string) => UpdateSubscriptionAction.error(message),
};
