import { Actions, TDefRequest } from '../Actions';
import BookmarksTypes, { TBookmarksState } from './types';

const GetBookmarksAction = new Actions('GET_BOOKMARKS', BookmarksTypes);
const GetBookmarksCsvAction = new Actions('GET_BOOKMARKSCSV', BookmarksTypes);

export interface TypeGetBookmarksR extends TDefRequest {
  data: {
    user_id?: string,
    email?: string,
    url?: string,
    site? : string,
    title? : string,
    dateStart? : Date | null,
    dateEnd? : Date | null;
    page?: number;
    productMainId ? : string;
    productId ? : string;
    typeSubscribe? : string;

  };
}

export const GetBookmarks = {
  request: (payload: TypeGetBookmarksR) => GetBookmarksAction.request(payload),
  success: (payload: TBookmarksState['data']) => GetBookmarksAction.success(payload || {}),
  error: (message: string) => GetBookmarksAction.error(message),
};
export const GetBookmarksCsv = {
  request: (payload: {}) => GetBookmarksCsvAction.request(payload),
  success: (payload: TBookmarksState['data']) => GetBookmarksCsvAction.success(payload || {}),
  error: (message: string) => GetBookmarksCsvAction.error(message),
};
