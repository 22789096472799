import { Reducer } from 'redux';
import ActionTypes, { TSideBarState } from './types';

export const initialState: TSideBarState = {
  page: { value: 0, name: 'Головна', type: 'main' },
};

const reducer: Reducer<TSideBarState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SIDEBAR:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export { reducer as SidebarReducer };
