import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, AlertTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Copyright } from '../components/Copyright';
import { setAuth, setUser } from '../store/auth/actions';
import { getToken } from '../utils/getToken';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '8px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '8px',
  },
}));

interface Values {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [errors, setErrors] = React.useState({ email: '', password: '' });

  const classes = useStyles();
  const dispatch = useDispatch();

  const validate = (values: Values) => {
    const errors = { email: '', password: '' };
    if (!values.email) {
      errors.email = `Обов'зкове поле`;
    }
    if (!values.password) {
      errors.password = `Обов'зкове поле`;
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validate({ email, password });
    setErrors(errors);
    if (errors.email) return setError(errors.email);
    if (errors.password) return setError(errors.password);
    setLoading(true);
    const data = await getToken(email, password);
    setLoading(false);

    if (data.status) {
      //@ts-ignore
      return setError(data.data?.message || 'Щось пiшло не так');
    }

    dispatch(
      setUser({
        exp: data.exp!,
        fio: data.fio!,
        token: data.accessToken!,
        type: data.type!,
        username: data.username!,
        rights: data.rights!,
        iat: data.iat!,
        id: data.id!,
      })
    );
    dispatch(setAuth(true));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} style={{ backgroundColor: '#f50057' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Увійти
        </Typography>
        <div className={classes.form}>
          <TextField
            required
            fullWidth
            id="email"
            label="Ваш Email"
            variant="outlined"
            margin="normal"
            value={email}
            error={!!errors.email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="password"
            label="Пароль"
            type="password"
            variant="outlined"
            value={password}
            error={!!errors.password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ height: '36px', margin: '24px 0px 16px' }}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={15} /> : 'Увiйти'}
          </Button>
        </div>
        {error ? (
          <Alert severity="error" style={{ width: '100%' }}>
            <AlertTitle>{error}</AlertTitle>
            {error}
          </Alert>
        ) : null}
      </div>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};
