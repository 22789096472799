import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import TextField from '@mui/material/TextField';
import { IUserAdmin } from '../../store/usersAdmin/types';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import { GetUsersAdmin } from '../../store/usersAdmin/actions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { Edit } from './Edit';
import moment from 'moment';
import roles from '../../utils/roles';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../modals';
import { DeleteUserAdmin } from '../../store/usersAdmin/actions';
import Button from '@mui/material/Button';


interface Column {
  id: 'id' | 'username' | 'fio' | 'adate' | 'role' | 'edit' | 'delete';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;
const rolesText: any  = [
  '',
  'Адміністратор',
  'Маркетолог',
  'Сапорт',
]
const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%', sort },
  { id: 'username', label: 'Email', align: 'left', sort },
  { id: 'fio', label: 'ФІО', align: 'left', sort },
  { id: 'adate', label: 'Дата створення', align: 'left', sort },
  { id: 'role', label: 'Роль', align: 'left', sort },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
    roles: [roles.ADMIN, roles.MARKETER],
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {};

export const ListUserAdmin: React.FC<PropsType> = () => {
  const [editData, setEditData] = React.useState<{ data?: IUserAdmin | null } | null>(null);
  const [id, setId] = React.useState('');
  const [username, setName] = React.useState('');
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IUserAdmin>('id');
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [hidden, setHidden] = React.useState(0);

  const dispatch = useDispatch();
  const { UsersAdmin, Auth } = useSelector((store: AppStore) => store);
  const classesTable = StylesTable();
  const { loading, handleSearch } = useSearch({
    SearchData: GetUsersAdmin,
  });
  const handleRemove = (id: number) => {
    if (!id) return null;
    dispatch(
      DeleteUserAdmin.request({
        id,
        callBack: (succ) => {
          if(succ){
            setHidden(id)
          }
        },
      })
    );
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUserAdmin) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (editData) {
 return <Edit titlePage={`Редагувати користувача ${editData?.data?.username}`} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <Box>
      <Button
          style={{ margin: '0 20px 10px 7px' }}
          variant="contained"
          color="primary"
          onClick={() => setEditData({ data: {id:0}})}
        >
          Створити користувача
        </Button>
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Пошук по ID"
          variant="outlined"
          value={id}
          className={classesTable.searchField}
          onChange={(e) => {
            setId(e.target.value);
            handleSearch(e.target.value, 'id');
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Email"
          variant="outlined"
          value={username}
          className={classesTable.searchField}
          onChange={(e) => {
            setName(e.target.value);
            handleSearch(e.target.value, 'name');
          }}
        />
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} rights={Auth.data?.rights} onRequestSort={handleRequestSort} columns={columns} />
          <TableBody>
            {UsersAdmin.data  && UsersAdmin.data.users && UsersAdmin.data.users.length > 0 &&
              stableSort(UsersAdmin.data.users, order, orderBy).map((row, i) => {
                return   hidden !==  row.id ? (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.id}`}>
                    <TableCell align="center">{row.id} </TableCell>
                    <TableCell className={classesTable.row}>{row.username}</TableCell>
                    <TableCell className={classesTable.row}>{row.fio}</TableCell>
                    <TableCell className={classesTable.row}>{moment(row.adate).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell className={classesTable.row}>{row.role && rolesText[row.role] ? rolesText[row.role] : '' }</TableCell>
                    {Auth.data?.rights[roles.ADMIN] || Auth.data?.rights[roles.MARKETER] ? (
                      <>
                           <TableCell align="center">
                        <IconButton aria-label="edit" onClick={() => setEditData({ data: row })}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                      <IconButton aria-label="delete" onClick={() => setIsModal({ id: row.id })}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                      </>
                 
                    ) : null}
                  </TableRow>
                ) : null;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            if (isApprove) {
              handleRemove(isModal.id);
            }
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
    
  );
};
