import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import React from "react";
import Button from "@mui/material/Button";
import {StylesEdit} from "../StylesEdit";
import {IScholl} from "../../store/scholl/types";
import {useDispatch} from "react-redux";
import {UpdateSchoolCertificate} from "../../store/scholl/actions";

type PropsType = {
  data?: IScholl | null;
  handleCancel: () => void;
};

const FormUserData: React.FC<PropsType> = ({data, handleCancel}) => {
  const classes = StylesEdit();
  const [firstName, setFirstName] = React.useState<string>(data?.changed_first_name || '');
  const [secondName, setSecondName] = React.useState<string>(data?.changed_second_name || '');
  const [middleName, setMiddleName] = React.useState<string>(data?.changed_middle_name || '');
  const [changedBy, setChangedBy] = React.useState<string>(data?.changed_by || '');
  const isDisabled = !firstName.trim() || !secondName.trim() || !middleName.trim() || !changedBy.trim();
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (isDisabled) {
      return;
    }
    dispatch(
      UpdateSchoolCertificate.request({
        app_id: data?.appId || 0,
        program_id: data?.id_program || '0',
        certificate_id: data?.id || 0,
        data: {
          changed_first_name: firstName,
          changed_second_name: secondName,
          changed_middle_name: middleName,
          changed_by: changedBy,
        },
        callBack: (success) => success && handleCancel(),
      })
    );
  };

  return (
    <>
      <div className={classes.inputsWrapper} style={{flex: '1 1'}}>
        <Box>
          <Typography component="h2" variant="h6">
            Редагування ПІБ для сертифікату
          </Typography>
        </Box>
        <TextField
          style={{marginTop: '20px'}}
          required
          name={'secondName'}
          size="small"
          id="outlined-basic"
          label="Прізвище"
          variant="outlined"
          value={secondName}
          className={classes.textFieldAutoWidth}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSecondName(event.target.value as string);
          }}
        />
        <TextField
          style={{marginTop: '20px'}}
          required
          name={'firstName'}
          size="small"
          id="outlined-basic"
          label="Ім'я"
          variant="outlined"
          value={firstName}
          className={classes.textFieldAutoWidth}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFirstName(event.target.value as string);
          }}
        />
        <TextField
          style={{marginTop: '20px'}}
          required
          name={'middleName'}
          size="small"
          id="outlined-basic"
          label="По-батькові"
          variant="outlined"
          value={middleName}
          className={classes.textFieldAutoWidth}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setMiddleName(event.target.value as string);
          }}
        />
        <TextField
          style={{marginTop: '40px'}}
          required
          name={'changedBy'}
          size="small"
          id="outlined-basic"
          label="ПІБ хто змінює дані"
          variant="outlined"
          value={changedBy}
          className={classes.textFieldAutoWidth}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setChangedBy(event.target.value as string);
          }}
        />
      </div>
      <Box style={{margin: '20px 0'}}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isDisabled}
          onClick={onSubmit}
          style={{height: '36px', marginRight: '40px'}}
        >
          Зберегти
        </Button>
        <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{height: '36px'}}>
          Повернутися
        </Button>
      </Box>
    </>
  );
}

export default FormUserData;