import { Reducer } from 'redux';
import ActionTypes, { TUsersAdminState } from './types';

export const initialState: TUsersAdminState = {
  data: null,
  editData: null,
  userSubs: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TUsersAdminState> = (state = initialState, action) => {
  switch (action.type) {

    case ActionTypes.GET_USERS_ADMIN_R:
    return { ...state, loading: true, errors: '' };
    case ActionTypes.UPDATE_USER_ADMIN_R:
    return { ...state, loading: true, errors: '' };

    case ActionTypes.DELETE_USER_ADMIN_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_USERS_ADMIN_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.UPDATE_USER_ADMIN_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.DELETE_USER_ADMIN_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_USERS_ADMIN_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: '',
      };

    case ActionTypes.UPDATE_USER_ADMIN_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.map((user) => {
                if (user.id === action.payload.id) {
                  return action.payload;
                }
                return user;
              }),
            }
          : state.data,
        editData: state.editData ? { ...state.editData, data: action.payload } : null,
      };
    case ActionTypes.DELETE_USER_ADMIN_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.filter((it) => it.id !== action.payload.id),
            }
          : { users: [action.payload], currentPage: 1, pages: 1 },
      };


    default:
      return state;
  }
};

export { reducer as UsersAdminReducer };
