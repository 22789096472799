import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { IUser } from '../../store/users/types';
import { StylesEdit } from '../StylesEdit';
import { EmailConfirm, SendEmailConfirm } from '../../store/users/actions';
import { FieldNewPasswd } from './FieldNewPasswd';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface PropsType {
  type: 'edit' | 'new';
  data?: IUser | null;
  handleCancel: () => void;
}

export const EditUsers: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const [loadingDone, setLoadingDone] = React.useState(false);
  const [loadingSend, setLoadingSend] = React.useState(false);

  const [errorEmail, setErrorEmail] = React.useState('');

  const { Users } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const classes = StylesEdit();

  const handleConfirmEmail = () => {
    if (!data) return null;
    setLoadingDone(true);
    dispatch(EmailConfirm.request({ id: data.absnum, callBack: () => setLoadingDone(false) }));
  };

  const handleSendConfirmEmail = () => {
    if (!data) return null;
    setLoadingSend(true);
    dispatch(
      SendEmailConfirm.request({
        id: data.absnum,
        callBack: (success) => {
          if (!success) {
            setErrorEmail('Виникла помилка');
            const timeId = setTimeout(() => {
              setErrorEmail('');
              clearTimeout(timeId);
            }, 1500);
          }
          setLoadingSend(false);
        },
      })
    );
  };

  return (
    <Box display="flex">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {type === 'new' ? 'Створити користувача' : 'Редагувати користувача'}
        </Typography>
        <TextField
          size="small"
          id="outlined-basic-absnum"
          label="Битрикс ID"
          variant="outlined"
          value={data ? Number(data.absnum) : ''}
          className={classes.textField}
        />
        <div className={classes.textField} style={{ display: 'flex' }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-basic-email">Пошта</InputLabel>
            <OutlinedInput
              style={{ margin: 0 }}
              size="small"
              id="outlined-basic-email"
              label="Пошта"
              value={data?.email || ''}
              endAdornment={
                <InputAdornment position="end">{data?.approved ? <DoneIcon color="success" /> : <CloseIcon color="error" />}</InputAdornment>
              }
            />
          </FormControl>
          {!data?.approved ? (
            <>
              <IconButton disabled={loadingDone} style={{ marginLeft: '10px' }} onClick={handleConfirmEmail}>
                {loadingDone ? <CircularProgress size={15} /> : <DoneIcon color="success" />}
              </IconButton>
              <div style={{ position: 'relative' }}>
                <IconButton disabled={loadingSend} style={{ marginLeft: '10px' }} onClick={handleSendConfirmEmail}>
                  {loadingSend ? <CircularProgress size={15} /> : <MarkEmailReadIcon color="primary" />}
                </IconButton>
                {errorEmail ? <div className={classes.errMesg}>{errorEmail}</div> : ''}
              </div>
            </>
          ) : null}
        </div>
        {data ? <FieldNewPasswd userId={data.absnum} url = 'users' /> : null}
        <TextField
          size="small"
          id="outlined-basic-lname"
          label="Прізвище"
          variant="outlined"
          value={data?.lname || ''}
          className={classes.textField}
        />
        <TextField size="small" id="outlined-basic-fname" label="Ім'я" variant="outlined" value={data?.fname || ''} className={classes.textField} />
        <TextField
          size="small"
          id="outlined-basic-mname"
          label="По батькові"
          variant="outlined"
          value={data?.mname || ''}
          className={classes.textField}
        />
        <Box style={{ margin: '20px 0' }}>
          <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </Box>
        {Users.errors ? (
          <Alert severity="error">
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
