import { Reducer } from 'redux';
import ActionTypes, { TUsersState } from './types';

export const initialState: TUsersState = {
  data: null,
  editData: null,
  userSubs: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TUsersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SUBSCRIPTIONS:
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.map((user) => {
                if (user.absnum === action.payload[0]?.userId || user.absnum === state.userSubs?.absnum) {
                  return { ...user, subscriptions: action.payload };
                }
                return user;
              }),
            }
          : state.data,
        userSubs: state.userSubs ? { ...state.userSubs, subscriptions: action.payload } : state.userSubs,
      };

    case ActionTypes.SET_EDIT_DATA:
      return { ...state, editData: action.payload };

    case ActionTypes.SET_USER_SUBSCRIPTIONS:
      return { ...state, userSubs: action.payload };

    case ActionTypes.AUTOLOGIN_BY_PRODUCT_R:
    case ActionTypes.AUTOLOGIN_R:
    case ActionTypes.GET_USERS_R:
    case ActionTypes.UPDATE_USER_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.AUTOLOGIN_BY_PRODUCT_E:
    case ActionTypes.AUTOLOGIN_E:
    case ActionTypes.GET_USERS_E:
    case ActionTypes.UPDATE_USER_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.AUTOLOGIN_BY_PRODUCT_S:
    case ActionTypes.AUTOLOGIN_S:
      return {
        ...state,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_USERS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: '',
      };

    case ActionTypes.UPDATE_USER_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.map((user) => {
                if (user.absnum === action.payload.absnum) {
                  return action.payload;
                }
                return user;
              }),
            }
          : state.data,
        editData: state.editData ? { ...state.editData, data: action.payload } : null,
      };

    case ActionTypes.EMAIL_CONFIRM_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.map((user) => {
                if (user.absnum === action.payload.absnum) {
                  return action.payload;
                }
                return user;
              }),
            }
          : state.data,
        editData: state.editData ? { ...state.editData, data: action.payload } : null,
      };

    case ActionTypes.DELETE_USER_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              users: state.data.users.filter((user) => user.absnum !== action.payload.absnum),
            }
          : state.data,
      };

    default:
      return state;
  }
};

export { reducer as UsersReducer };
