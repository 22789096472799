export interface TBookmarksState {
  data: {
    bookmarks: IBookmarks[];
    optionProducts: { value: string; label: string; }[];
    url: string;
    currentPage: number;
    pages: number;
    total: number;
  } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IBookmarks {
  user_id: string;
  email: string;
  site: string;
  title: string; 
  url: string;
  date_create: string;
  last_view_cabinet: string;
  count_click_cabinet: number;
  last_view_widget: string;
  count_click_widget: number;
  date_delete : string;
}

enum AppsTypes {
  GET_BOOKMARKS_R = '@@bookmarks/GET_BOOKMARKS_R',
  GET_BOOKMARKS_S = '@@bookmarks/GET_BOOKMARKS_S',
  GET_BOOKMARKS_E = '@@bookmarks/GET_BOOKMARKS_E',

  GET_BOOKMARKSCSV_R = '@@bookmarkscsv/GET_BOOKMARKSCSV_R',
  GET_BOOKMARKSCSV_S = '@@bookmarkscsv/GET_BOOKMARKSCSV_S',
  GET_BOOKMARKSCSV_E = '@@bookmarkscsv/GET_BOOKMARKSCSV_E',
}

export default AppsTypes;
