import React from 'react';
import { useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { AppStore } from '../store/applicationState';
import { drawerWidth } from '../config';
import { ListSubscriptions } from '../components/Subscriptions/ListSubscriptions';
import { ListUsers } from '../components/Users/ListUsers';
import { ListSupport } from '../components/Supports/ListSupport';
import { ListAllProducts } from '../components/AllProducts/ListAllProducts';
import { ListActions } from '../components/Actions/ListActions';
import { ListApps } from '../components/Apps/ListApps';
import { ListUserAdmin } from '../components/UsersAdmin/List';
import { ListProductActions } from '../components/ProductActions/ListActions';
import { ListBookmarks } from '../components/Bookmarks/List';
import { ListSchool } from '../components/School/List';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: {
    minHeight: ' 64px',
  },
  content: {
    flexGrow: 1,
  },
  base: {
    minHeight: 'calc(100vh - 96px)',
    padding: '15px',
    position: 'relative',
  },
}));

export const Home: React.FC = () => {
  const { Sidebar: RSidebar } = useSelector((store: AppStore) => store);

  const classes = useStyles();

  const renderRoutePage = () => {
    if (RSidebar.page.value === 2) {
      return <ListSubscriptions />;
    }
    if (RSidebar.page.value === 3) {
      return <ListUsers />;
    }
    if (RSidebar.page.value === 4) {
      return <ListSupport />;
    }
    if (RSidebar.page.value === 6) {
      return <ListAllProducts />;
    }
    if (RSidebar.page.value === 7) {
      return <ListActions />;
    }
    if (RSidebar.page.value === 8) {
      return <ListApps/>;
    }
    if (RSidebar.page.value === 9) {
      return <ListUserAdmin/>;
    }
    if (RSidebar.page.value === 10) {
      return <ListProductActions/>;
    }
    if (RSidebar.page.value === 11) {
      return <ListBookmarks/>;
    }
    if (RSidebar.page.value === 12) {
      return <ListSchool/>;
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        <div className={classes.appBarSpacer} />
        <Paper className={classes.base}>{renderRoutePage()}</Paper>
      </Box>
    </div>
  );
};
