export interface TSchollState {
  data: {
    scholl: IScholl[];
    optionProducts: { value: string; label: string; }[];
    url: string;
    currentPage: number;
    pages: number;
    total: number;
  } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
  dataUpdatedItem: IScholl | null;
  readonly loadingUpdatedItem: boolean;
  readonly errorsUpdatedItem?: string | undefined;
}

export interface IScholl {
  id?: number;
  appId?: number;
  userId: number;
  type?: number;
  second_name: string;
  first_name: string;
  middle_name: string;
  changed_second_name: string;
  changed_first_name: string;
  changed_middle_name: string;
  changed_by: string;
  changed_NP_data_by: string;
  recipient_second_name?: string;
  recipient_first_name?: string;
  recipient_middle_name?: string;
  recipient_phone?: string;
  recipient_city?: string;
  recipient_city_name?: string;
  recipient_post?: string;
  no_print?: number;
  date_test?: Date;
  date_create?: Date;
  product_id: number;
  version_product: string;
  product_parent_name?: string;
  fintest?: number;
  ukd?: string;
  recipient_address?: string;
  approved?: number;
  id_program?: string;
  dateStart: string;
  dateEnd: string;
  product_name?: string;
  lname: string;
  fname: string;
  mname: string;
  phone: string;
  WarehouseNumber: string;
  WarehouseAddress: string;
  WarehouseCity: string;
  WarehouseArea: string;
  WarehouseRegion: string;
  printed: string;
  version_product_name: string;
  ukd_product_id: string;
  updated_at: string;
  Description: string;
  product_parent_id?: string;
  ukd_product_name?: string;

}

enum AppsTypes {
  GET_SCHOLL_R = '@@scholl/GET_SCHOLL_R',
  GET_SCHOLL_S = '@@scholl/GET_SCHOLL_S',
  GET_SCHOLL_E = '@@scholl/GET_SCHOLL_E',

  GET_SCHOLLCSV_R = '@@schollcsv/GET_SCHOLLCSV_R',
  GET_SCHOLLCSV_S = '@@schollcsv/GET_SCHOLLCSV_S',
  GET_SCHOLLCSV_E = '@@schollcsv/GET_SCHOLLCSV_E',

  UPDATE_SCHOOL_CERTIFICATE_R = '@@scholl/UPDATE_SCHOOL_CERTIFICATE_R',
  UPDATE_SCHOOL_CERTIFICATE_S = '@@scholl/UPDATE_SCHOOL_CERTIFICATE_S',
  UPDATE_SCHOOL_CERTIFICATE_E = '@@scholl/UPDATE_SCHOOL_CERTIFICATE_E',

  UPDATE_CERTIFICATE_NOVA_POST_R = '@@scholl/UPDATE_NOVA_POST_CERTIFICATE_R',
  UPDATE_CERTIFICATE_NOVA_POST_S = '@@scholl/UPDATE_NOVA_POST_CERTIFICATE_S',
  UPDATE_CERTIFICATE_NOVA_POST_E = '@@scholl/UPDATE_NOVA_POST_CERTIFICATE_E',
}

export default AppsTypes;
