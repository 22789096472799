export interface TSubscriptionsState {
  data: { subscriptions: ISubscription[]; currentPage: number; pages: number } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface ISubscription {
  code: string;
  productId: number;
  parentId: number;
  userId: number;
  dateStart: Date;
  dateEnd: Date;
  createDate: Date;
  status: number;
  lname: string;
  fname: string;
  mname: string;
  subProduct?: string;
  mainProduct?: string;
  type_code?: string;

}

enum ActionTypes {
  GET_SUBSCRIPTIONS_R = '@@subscriptions/GET_SUBSCRIPTIONS_R',
  GET_SUBSCRIPTIONS_S = '@@subscriptions/GET_SUBSCRIPTIONS_S',
  GET_SUBSCRIPTIONS_E = '@@subscriptions/GET_SUBSCRIPTIONS_E',

  UPDATE_SUBSCRIPTION_R = '@@subscriptions/UPDATE_SUBSCRIPTION_R',
  UPDATE_SUBSCRIPTION_S = '@@subscriptions/UPDATE_SUBSCRIPTION_S',
  UPDATE_SUBSCRIPTION_E = '@@subscriptions/UPDATE_SUBSCRIPTION_E',
}

export default ActionTypes;
