import { Actions, TDefRequest } from '../Actions';
import AppsTypes, { TAppsState } from './types';

const GetActionsAction = new Actions('GET_ACTIONS', AppsTypes);

export interface TypeGetActionsR extends TDefRequest {
  data: {
    absnum?: number;
    name?: string;
    url?: string;
    main_product? : string;
    main_product_id?: string;
    productVersion?: string;
    page?: number;
  };
}

export const GetApps = {
  request: (payload: TypeGetActionsR) => GetActionsAction.request(payload),
  success: (payload: TAppsState['data']) => GetActionsAction.success(payload || {}),
  error: (message: string) => GetActionsAction.error(message),
};
