import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { IProduct } from '../../store/products/types';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import { GetApps } from '../../store/apps/actions';
import Button from '@mui/material/Button';

interface Column {
  id: 'name' | 'appId' | 'url' | 'main_product' | 'main_product_id' | 'productVersion';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'appId', label: 'APP_ID', align: 'center', sort },
  { id: 'name', label: 'Сайт', align: 'center', sort },
  { id: 'url', label: 'URL', align: 'center', sort },
  { id: 'main_product', label: 'Продукт', align: 'center', sort },
  { id: 'main_product_id', label: 'Номер головного продукту', align: 'center', sort },
  { id: 'productVersion', label: 'Версія продукта', align: 'center', sort },
];

type PropsType = {};

export const ListApps: React.FC<PropsType> = () => {
  const [absnum, setAbsnum] = React.useState('');
  const [name, setName] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [main_product, setMainProduct] = React.useState('');
  const [main_product_id, setMainProductId] = React.useState('');
  const [productVersion, setProductVersion] = React.useState('');

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');

  const { Apps } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const { loading, handleSearch } = useSearch({
    SearchData: GetApps,
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleChangeData<T>(value: T, query: string, callBack: (value: T) => void) {
    const data: any = {
      absnum,
      name,
      url,
      main_product,
      main_product_id
    };
    callBack(value);
    delete data[query];
    handleSearch(value as unknown as string, query, data);
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!Apps.data) return null;
    if (Apps.data.pages >= page) {
      dispatch(
        GetApps.request({
          data: {
            name,
            url,
            main_product,
            page,
            main_product_id
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setAbsnum('');
    setName('');
    setUrl('');
    setMainProduct('');
    dispatch(GetApps.request({ data: {} }));
  };


  const exportFile = () => {
    const apps = Apps.data?.apps || [];
    const csvRows = apps.map((row) => {
      let productVersion = typeof row.productVersion === 'string' ? row.productVersion.split(',').join('; ') : '';
      const name = row.name.includes(';') ? `"${row.name}"` : row.name;
      productVersion = productVersion.includes(';') ? `"${productVersion}"` : productVersion;

      return [
        row.absnum,
        name,
        row.main_product,
        row.main_product_id,
        productVersion,
      ];
    });
    
    const headers = ['Absnum', 'Name', 'Main Product', 'Main Product ID', 'Product Version'];
    const csvData = [headers, ...csvRows].map(row => row.join(',')).join('\n');
    
    
    if (csvData) {
      const data = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'apps.csv');
      tempLink.click();
    }
  };
  
  
  
  return (
    <>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="APP_ID"
          variant="outlined"
          value={absnum}
          className={classesTable.searchField}
          onChange={(e) => {
            setAbsnum(e.target.value);
            handleSearch(e.target.value, 'absnum');
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Сайт"
          variant="outlined"
          value={name}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'name', setName);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="URL"
          variant="outlined"
          value={url}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'url', setUrl);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Продукт"
          variant="outlined"
          value={main_product}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'main_product', setMainProduct);
          }}
        />
         <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Номер головного продукту"
          variant="outlined"
          value={main_product_id}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'main_product_id', setMainProductId);
          }}
        />
          <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="версія продукту"
          variant="outlined"
          value={productVersion}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'productVersion', setProductVersion);
          }}
        />

        <Button variant="contained" color="primary" onClick={clearFilters} style={{ height: '40px' }}>
          Очистити фільтри
        </Button>
        <Button variant="contained" color="primary" onClick={exportFile} style={{ height: '40px', marginLeft: '10px' }}>
          Вивантажити у CSV
        </Button>
   
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns} />
          <TableBody>
            {Apps && Apps.data &&
              stableSort(Apps.data.apps, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">
                      {row.absnum}

                    </TableCell>
                    <TableCell align="center">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.url}
                    </TableCell>
                    <TableCell align="center">
                      {row.main_product}
                    </TableCell>
                    <TableCell align="center">
                      {row.main_product_id}
                    </TableCell>
                    <TableCell align="center" style={{ textOverflow: 'ellipsis' }}>
                      {row.productVersion && typeof row.productVersion === 'string' ? row.productVersion.split(',').join('; ') : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Apps && Apps.data ? Apps.data.pages : 0}
        page={Apps && Apps.data ? Apps.data.currentPage : 1}
        onChange={handleChangePage}
      />
    </>
  );
};
