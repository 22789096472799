export interface TUsersAdminState {
  data: { users: IUserAdmin[]; currentPage: number; pages: number } | null;
  editData: { type: 'edit' | 'new'; data?: IUserAdmin | null } | null;
  userSubs: IUserAdmin | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IUserAdmin {
  id: number;
  fio?: string;
  username?: string;
  adate?: string;
  role?: string;
  password?: string;
}
export interface IDeleteUserAdmin {
  id: number;
}


enum ActionTypes {
  GET_USERS_ADMIN_R = '@@users/GET_USERS_ADMIN_R',
  GET_USERS_ADMIN_S = '@@users/GET_USERS_ADMIN_S',
  GET_USERS_ADMIN_E = '@@users/GET_USERS_ADMIN_E',

  GET_USER_R = '@@users/GET_USER_R',
  GET_USER_S = '@@users/GET_USER_S',
  GET_USER_E = '@@users/GET_USER_E',

  SEARCH_USERS_ADMIN_R = '@@users/SEARCH_USERS_ADMIN_R',
  SEARCH_USERS_ADMIN_S = '@@users/SEARCH_USERS_ADMIN_S',
  SEARCH_USERS_ADMIN_E = '@@users/SEARCH_USERS_ADMIN_E',

  UPDATE_USER_ADMIN_R = '@@users/UPDATE_USER_ADMIN_R',
  UPDATE_USER_ADMIN_S = '@@users/UPDATE_USER_ADMIN_S',
  UPDATE_USER_ADMIN_E = '@@users/UPDATE_USER_ADMIN_E',

  DELETE_USER_ADMIN_R = '@@users/DELETE_USER_ADMIN_R',
  DELETE_USER_ADMIN_S = '@@users/DELETE_USER_ADMIN_S',
  DELETE_USER_ADMIN_E = '@@users/DELETE_USER_ADMIN_E',
}

export default ActionTypes;
