import { TOKEN } from '../config';
import { callApi } from './callApi';
import { checkToken } from './checkToken';
import { ThrowError } from './throwError';

type TSuccess = {
  accessToken?: string;
  refreshToken?: string;
  detail?: any;
  status?: number;
  exp?: number;
  fio?: string;
  id?: number;
  type?: string;
  username?: string;
  rights?: { [x: number]: number };
  iat?: number;
};

export const getToken = async (email: string, password: string): Promise<TSuccess> => {
  try {
    const data: TSuccess = await callApi({
      path: '/auth/login',
      data: {
        email,
        password,
      },
      method: 'post',
    });

    if (!data.accessToken || !data.refreshToken) {
      ThrowError.detail = 'Data does not have token';
      return ThrowError.init();
    }

    if (data.accessToken && data.refreshToken) {
      localStorage.setItem(TOKEN.access, data.accessToken);
      localStorage.setItem(TOKEN.refresh, data.refreshToken);
    }
    const tokenData = await checkToken();
    return {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      ...tokenData,
    };
  } catch (e: any) {
    if (e.status) {
      return e as TSuccess;
    }
    return {
      detail: e,
      status: 401,
    } as TSuccess;
    // console.log(e);
  }
};
