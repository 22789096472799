import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { AppStore } from '../../store/applicationState';
import { setAuth, setUser } from '../../store/auth/actions';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hoverBtn: {
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
}));

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const { Auth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <AccountCircle />
          <Typography style={{ marginLeft: '10px' }}>{Auth.data?.fio || 'Нема данних'}</Typography>
          <Button
            className={classes.hoverBtn}
            variant="outlined"
            color="inherit"
            onClick={() => {
              localStorage.clear();
              dispatch(setAuth(false));
              dispatch(setUser(null));
            }}
            startIcon={<ExitToApp />}
            style={{ marginLeft: '20px' }}
          >
            Вийти
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
