import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { StylesEdit } from '../StylesEdit';
import { IUserAdmin } from '../../store/usersAdmin/types';
import { UpdateUserAdmin } from '../../store/usersAdmin/actions';
import { FieldNewPasswd } from '../Users/FieldNewPasswd';
import { collectionValidate } from '../../utils/collectionValidate';
import { SelectOutline } from '../ui';

interface PropsType {
  titlePage?: string;
  data?: IUserAdmin | null;
  handleCancel: () => void;
}

export const Edit: React.FC<PropsType> = ({ titlePage, data, handleCancel }) => {
  const [username, setName] = React.useState<string>(data?.username || '');
  const [role, setRole] = React.useState<string>(data?.role|| '' );
  const [fio, setFio] = React.useState<string>(data?.fio || '');
  const [error, setError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { Products } = useSelector((store: AppStore) => store);
  
  const dispatch = useDispatch();
  const classes = StylesEdit();

  const onSubmit = () => {
    if (!data) return null;
    if (!username ) return setError('поле обовʼязкове');
    const newUser = {
      id : data.id
    } as IUserAdmin;

    if(data?.username !== username){
      if (!collectionValidate['email'].checkValue(username)) {
        return setError('Email : ' +collectionValidate['email'].error);
      }
      setError('');
      newUser.username = username;
    }
    if(data?.role !== role){
      newUser.role = role;
    }
    if(data?.fio !== fio){
      if (fio.length < 2) {
        return setError('ФИО : обовязкове поле');
      }
      setError('');
      newUser.fio = fio;
    }
  
    if(data.id === 0){
      if (!collectionValidate['password'].checkValue(password)) {
        return setError('Пароль : ' + collectionValidate['password'].error);
      }
      newUser.password = password;

    }
      dispatch(
        UpdateUserAdmin.request({
          data: newUser,
          callBack: (succ) => succ && handleCancel(),
        })
      );
 
  };
  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component="h1" variant="h5">
            {data?.id ? titlePage : 'Новий користувач'}
          </Typography>
        </Box>
        <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={username}
          error={!!error && !username}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />

          <SelectOutline
          style={{ marginTop: '20px' ,width : '900px' }} 
          title="Роль"
          options={[
            { value: '1', label: 'Адміністратор' },
            { value: '2', label: 'Маркетолог' },
            { value: '3', label: 'Cапорт' },
          ]}
          handleChange={(value) => setRole(value)}
          value={data ? role : ''}
        />
           <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic"
          label="ФИО"
          variant="outlined"
          value={fio}
          error={!!error && !username}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFio(event.target.value as string);
          }}
        />
        {data?.id === 0 ? 
        (  
          <TextField
            style={{ marginTop: '20px' }}
            required
            size="small"
            id="outlined-basic"
            label="Пароль"
            variant="outlined"
            value={password}
            error={!!error && !username}
            className={classes.textField}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setPassword(event.target.value as string);
            }}
          />
        ) : (     
             data ? <FieldNewPasswd userId={data.id} url = 'usersAdmin' /> : null
        ) }
       


        <Box style={{ margin: '20px 0' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Products.loading}
            onClick={onSubmit}
            style={{ height: '36px', marginRight: '40px' }}
          >
            {Products.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity="error">
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        ) : null}
   
      </div>
    </Box>
  );
};
