import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
//import { Link } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { ISubscription } from '../../store/subscriptions/types';
import moment from 'moment';
import { GetSubscriptions, UpdateSubscription } from '../../store/subscriptions/actions';
import Button from '@mui/material/Button';
import { BasicDatePicker, SelectOutline, SelectSearchReq } from '../ui';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchProducts } from '../../hooks/useSearchProducts';
import { Order, stableSort } from '../../utils/stableSort';
import { EnhancedTableHead } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import { UserSubscriptions } from '../Users/UserSubscriptions';
import { GetUser, setUserSubs } from '../../store/users/actions';
import { collectionStatus } from '../../utils/consts';
import { Checkbox, FormControlLabel } from '@mui/material';

// import roles from '../../utils/roles';
// import IconButton from '@mui/material/IconButton';
// import EditIcon from '@mui/icons-material/Edit';
import { ChangeStatus, ChangeUser } from '../modals';

interface Column {
  id: 'id' | 'code' | 'mainProduct' | 'status' | 'description' | 'date' | 'share' | 'delete' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  sort?: boolean;
  roles?: number[];
}

const sort = true;
const type_codes = {
'32ddb4dd-302b-414f-93f9-c0e348cb9172' : 'КЛ ДД',
'756900c6-12dc-426e-aec1-060495f60a04' : 'ОП ДД',
'b8794283-ea41-4386-aa78-aff0f94c7ffa' : 'Клас.',
'f777b326-b2ee-405b-b75c-4b4035209dc7' : 'Співроб.',
'fb1b26ed-776d-4e4c-aba3-95ef4ed60927' : 'Подар.',
}

const columns: Column[] = [
  { id: 'code', label: 'УКД', align: 'center', width: '22%', sort },
  { id: 'id', label: 'Код/Тип УКД', align: 'center', width: '150px', sort },
  { id: 'mainProduct', label: 'Головний продукт', align: 'left', sort },
  { id: 'status', label: 'Статус', align: 'left', width: '120px', sort },
  { id: 'date', label: 'Період', align: 'left', width: '120px', sort },
  // {
  //   id: 'edit',
  //   label: 'Змінити користувача',
  //   align: 'center',
  //   width: '100px',
  //   roles: [roles.ADMIN, roles.SUPPORT],
  // },
];

type PropsType = {};

export const ListSubscriptions: React.FC<PropsType> = () => {
  const [productMain, setProductMain] = React.useState<IDataOpt | null>(null);
  const [productId, setProductId] = React.useState('');
  const [ukd, setUkd] = React.useState('');
  const [dateStart, setDateStart] = React.useState<Date | null>(null);
  const [dateEnd, setDateEnd] = React.useState<Date | null>(null);
  const [status, setStatus] = React.useState('2');
  const [loadingUser, setLoadingUser] = React.useState(0);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'share'; data: ISubscription } | null>(null);
  const [typeSubscribe, setTypeSubscribe] = React.useState('0');
  const [isActiveUkd, setIsActiveUkd] = React.useState(false);

  const [isChangeStatus, setIsChangeStatus] = React.useState<{ id: string; userId: number; value: string } | null>(null);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof ISubscription>('code');

  const { Subscriptions, Sidebar, Users, Auth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { loading, handleSearch } = useSearch({
    SearchData: GetSubscriptions,
  });
  const { subOptions, mainOptions, selectedMainProduct, handleSearch: handleSearchProduct } = useSearchProducts();

  React.useEffect(() => {
    if (Sidebar.page.type) {
      dispatch(setUserSubs(null));
    }
  }, [dispatch, Sidebar.page]);

  React.useEffect(() => {
    if (moment(dateStart).isValid() || moment(dateEnd).isValid() || status || productMain || productId || typeSubscribe) {
      dispatch(
        GetSubscriptions.request({
          data: {
            ukd,
            dateStart,
            dateEnd,
            status,
            typeSubscribe,
            productMainId: productMain ? String(productMain.id) : '',
            productId,
            isActiveUkd
          },
        })
      );
    }
  }, [dispatch, ukd, dateStart, dateEnd, status, productId, productMain, typeSubscribe, isActiveUkd]);

  const clearFilters = () => {
    setProductMain({ id: 0, label: '' });
    setProductId('');
    setUkd('');
    setDateStart(null);
    setDateEnd(null);
    setStatus('');
    setTypeSubscribe('');
    dispatch(
      GetSubscriptions.request({
        data: {},
      })
    );
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ISubscription) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, page: number) => {
    if (!Subscriptions.data) return null;
    if (Subscriptions.data.pages >= page) {
      dispatch(
        GetSubscriptions.request({
          data: {
            page,
            dateStart,
            dateEnd,
            status,
            typeSubscribe,
            productMainId: productMain ? String(productMain.id) : '',
            productId,
            isActiveUkd
          },
        })
      );
    }
  };

  const handleChageStatus = (id: string, userId: number, status: number) => {
    dispatch(UpdateSubscription.request({ data: { ukd: id, userId, status } }));
  };

  const handleShowClient = (userId: number) => {
    if (Users.data) {
      const user = Users.data.users.find((it) => it.absnum === userId);
      if (user) {
        dispatch(setUserSubs(user));
        return;
      }
    }
    setLoadingUser(userId);
    dispatch(
      GetUser.request({
        id: userId,
        callBack: (success, data) => {
          if (success && data) {
            dispatch(setUserSubs(data));
          }
          setLoadingUser(0);
        },
      })
    );
  };

  if (Users.userSubs) {
    return <UserSubscriptions data={Users.userSubs} handleCancel={() => dispatch(setUserSubs(null))} />;
  }

  return (
    <>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          size="small"
          id="outlined-basic-id"
          label="Пошук по UKD"
          variant="outlined"
          value={ukd}
          className={classesTable.searchField}
          onChange={(event) => {
            setUkd(event.target.value);
            handleSearch(event.target.value, 'ukd');
          }}
        />
        <SelectSearchReq
          data={productMain}
          style={{ marginRight: '10px', width: '210px' }}
          title="Головний продукт"
          className={classesTable.searchField}
          onChange={(data) => {
            setProductId('');
            selectedMainProduct(data);
            setProductMain(data);
          }}
          options={mainOptions}
          handleRequest={handleSearchProduct}
        />
        <SelectOutline
          style={{ marginRight: '10px', width: '210px' }}
          id="outlined-basic"
          title="Версія продукту"
          value={productId}
          className={classesTable.searchField}
          options={subOptions}
          handleChange={setProductId}
        />
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker id="dateStart" label="Дата старту" value={dateStart} onChange={setDateStart} />
        </div>
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker id="dateEnd" label="Дата закінчення" value={dateEnd} onChange={setDateEnd} />
        </div>
        <SelectOutline
          style={{ width: '210px' }}
          className={classesTable.searchField}
          title="Статус"
          options={[
            { value: '-1', label: 'Всі' },
            { value: '0', label: 'Створено' },
            { value: '1', label: 'Переданий' },
            { value: '2', label: 'Активовано' },
            { value: '3', label: 'Заблокований' },
            { value: '4', label: 'Деактивований' },
          ]}
          handleChange={(value) => setStatus(value)}
          value={status}
        />
          <SelectOutline
          style={{ width: '210px' }}
          className={classesTable.searchField}
          title="Тип підписки"
          options={[
            { value: '1', label: 'Демодоступ' },
            { value: '0', label: 'Підписники' },
          ]}
          handleChange={(value) => setTypeSubscribe(value)}
          value={typeSubscribe}
        />
      <FormControlLabel
        control={<Checkbox onChange={(event) => setIsActiveUkd(event.target.checked)} />}
        label="Чинна передплата"
      />

        {loading ? <CircularProgress size={25} /> : null}
        <Button variant="contained" color="primary" onClick={clearFilters} style={{ height: '36px' }}>
          Очистити фільтри
        </Button>
      </Box>
      <TableContainer className={classesTable.container} style={{ maxHeight: 'calc(100vh - 260px)' }}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} columns={columns} rights={Auth.data?.rights} onRequestSort={handleRequestSort} />
          <TableBody>
            {Subscriptions.data &&
              stableSort(Subscriptions.data.subscriptions, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.code}`}>
                    <TableCell align="center">
                      <>
                        {row.code}
                        <br />
                        {row.userId ? (
                          <Button variant="text" disabled={!!loadingUser} onClick={() => handleShowClient(row.userId)}>
                            {row.userId === loadingUser ? <CircularProgress size={15} /> : `${row.userId}`}
                          </Button>
                        ) : null}
                      </>
                    </TableCell>
                    <TableCell>
                      {row.productId}
                          <br/>
                      {row.type_code ? type_codes[row.type_code as keyof typeof type_codes] : ''}
                    </TableCell>
                    <TableCell className={classesTable.row}>
                      <>
                        {row.mainProduct ? `№ ${row.parentId}, ${row.mainProduct}` : ''}
                        <br />
                        {row.subProduct ? `№ ${row.productId}, ${row.subProduct}` : ''}
                      </>
                    </TableCell>
                    <TableCell className={classesTable.row} style={{ textOverflow: 'clip', color: collectionStatus[row.status]?.color || '' }}>
                      {/* { row.status !==3 ? (
                      <Link
                        style={{ cursor: 'pointer', color: collectionStatus[row.status]?.color || '' }}
                        onClick={() =>
                          Auth.data?.rights[roles.ADMIN] && row.status !== 3 && setIsChangeStatus({ id: row.code, userId: row.userId, value: String(row.status) })
                        }
                      >
                      {collectionStatus[row.status]?.name || ''}
                      </Link>
                      ):(
                         collectionStatus[row.status]?.name || '' 
                      )} */}
                      {  collectionStatus[row.status]?.name || '' }
                    </TableCell>
                    <TableCell className={classesTable.row}>
                      <>
                        {moment(row.dateStart).format('DD.MM.YYYY')} —<br />
                        {moment(row.dateEnd).format('DD.MM.YYYY')}
                      </>
                    </TableCell>
                    {/* {Auth.data?.rights[roles.ADMIN] || Auth.data?.rights[roles.SUPPORT] ? (
                      <TableCell align="center">
                        {row.status === 2 ? (
                          <IconButton aria-label="edit" onClick={() => setEditData({ type: 'edit', data: row })}>
                            <EditIcon />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    ) : null} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Subscriptions.data ? Subscriptions.data.pages : 0}
        page={Subscriptions.data ? Subscriptions.data.currentPage : 1}
        onChange={handleChangePage}
      />
      {isChangeStatus ? (
        <ChangeStatus
          handleClose={() => {
            setIsChangeStatus(null);
          }}
          handleConfirm={(value) => {
            handleChageStatus(isChangeStatus.id, isChangeStatus.userId, Number(value));
            setIsChangeStatus(null);
          }}
          initValue={isChangeStatus.value}
          open={!!isChangeStatus}
        />
      ) : null}
      {editData ? <ChangeUser data={editData.data} handleClose={() => setEditData(null)} open={!!editData.data} /> : null}
    </>
  );
};
