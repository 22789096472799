import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { SearchUsers } from '../store/users/actions';
import { IUser } from '../store/users/types';
import { mergeFio } from '../utils/mergeFio';

export const useSearchUsers = () => {
  const [options, setOptions] = React.useState<IDataOpt[] | undefined>();

  const { Users } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Users.data) {
      const opt = Users.data.users.map((it) => ({ id: it.absnum, label: `№ ${it.absnum}, ${mergeFio({ l: it.lname, f: it.fname, m: it.mname })}` }));
      setOptions(opt);
    }
  }, [Users.data]);

  const handleSearch = (value: string, callBack?: (resData: IDataOpt[]) => void) => {
    if (value) {
      let data: { [x: string]: string | number } = {};
      const arr = value.split(' ');
      const id = !Number.isNaN(Number(arr[0])) ? Number(arr[0]) : null;
      if (id) {
        data['id'] = id;
      } else {
        if (arr[0]) {
          data['lastName'] = arr[0];
        }
        if (arr[1]) {
          data['firstName'] = arr[1];
        }
        if (arr[2]) {
          data['middleName'] = arr[2];
        }
        if (arr.length === 1) {
          data['firstName'] = arr[0];
          data['middleName'] = arr[0];
        }
      }

      dispatch(
        SearchUsers.request({
          data,
          callBack: (succ, data) => {
            if (succ && Array.isArray(data) && callBack) {
              const opt = data.map((it: IUser) => ({
                id: it.absnum,
                label: `№ ${it.absnum}, ${mergeFio({ l: it.lname, f: it.fname, m: it.mname })}`,
              }));
              callBack(opt);
            } else {
              callBack && callBack(options || []);
            }
          },
        })
      );
    } else {
      callBack && callBack(options || []);
    }
  };

  return {
    options,
    handleSearch,
  };
};
