import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  GetProductActions,
  TypeGetProductActionsR,
  GetProductActionsXLSX,
  GetProductActionsFiles,
  DownloadProductActionsFile, TypeDownloadProductActionsFileR
} from './actions';
import ActionTypes, {TProductActionsState, YearFiles} from './types';

function* getProductActionsWorker(action: ReturnType<typeof GetProductActions.request>): Generator {
  const { data, callBack } = action.payload as TypeGetProductActionsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/productActions?${query}` : '/productActions',
    })) as TProductActionsState['data'];
    yield put(GetProductActions.success(resp));
  } catch (e) {
    success = false;
    yield put(GetProductActions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getProductXLSXActionsWorker(action: ReturnType<typeof GetProductActionsXLSX.request>): Generator {
  const { data } = action.payload as TypeGetProductActionsR;
  const query = buildQuery(data);
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/productActions/download-xlsx?${query}`,
      isBlobResponseType: true,
    })) as Blob;
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `productActions.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put(GetProductActionsXLSX.success(!!resp));
    }
  } catch (e) {
    yield put(GetProductActionsXLSX.error(e as string));
  }
}

function* getProductActionsFilesWorker(action: ReturnType<typeof GetProductActionsFiles.request>): Generator {
  const { callBack } = action.payload as TypeGetProductActionsR;

  let success = true;

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: '/v1/product-actions/files',
    })) as YearFiles;
    yield put(GetProductActionsFiles.success(resp));
  } catch (e) {
    success = false;
    yield put(GetProductActionsFiles.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* downloadProductActionsFileWorker(action: ReturnType<typeof DownloadProductActionsFile.request>): Generator {
  const { path, callBack } = action.payload as TypeDownloadProductActionsFileR;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/v1/product-actions/files/download?path=${path}`,
      isBlobResponseType: true,
    })) as Blob;
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${path}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      yield put(DownloadProductActionsFile.success(!!resp));
    }
  }
  catch (e) {
    yield put(DownloadProductActionsFile.error(e as string));
  }
  finally {
    if (callBack) yield call(callBack, true);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_PRODUCTACTIONS_R, getProductActionsWorker);
  yield takeEvery(ActionTypes.GET_PRODUCTACTIONS_FILES_R, getProductActionsFilesWorker);
  yield takeEvery(ActionTypes.GET_PRODUCTACTIONS_XLSX_R, getProductXLSXActionsWorker);
  yield takeEvery(ActionTypes.GET_PRODUCTACTIONS_DOWNLOAD_FILE_R, downloadProductActionsFileWorker);
}

export default function* productActionsSaga() {
  yield all([fork(watchFetchRequest)]);
}
