import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { useSearchProducts } from '../../hooks/useSearchProducts';
import { Autologin, AutologinByProduct } from '../../store/users/actions';
import { IUser } from '../../store/users/types';
import { SelectOutline, SelectSearchReq } from '../ui';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '2px solid #000',
  },
  textField: {
    textAlign: 'left',
    marginTop: '20px',
    width: '600px',
  },
  link: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'space-between',
    height: '35px',
    alignItems: 'center',
  },
  boxFooter: {
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgb(243, 240, 240)',
  },
}));

interface BoxModalProps {
  open: boolean;
  data: IUser;
  handleClose: () => void;
}

interface IOption {
  [x: string]: number;
}

export const AutologinModal: React.FC<BoxModalProps> = ({ open, data, handleClose }) => {
  const [productId, setProductId] = React.useState('');
  const [link, setLink] = React.useState('');
  const [errors, setErrors] = React.useState('');

  const [mainProduct, setMainProduct] = React.useState<IOption>({});
  const [subProduct, setSubProduct] = React.useState<IOption>({});

  const { Users } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const { subOptions, mainOptions, selectedMainProduct, handleSearch } = useSearchProducts(mainProduct, subProduct);
  const classes = useStyles();

  React.useEffect(() => {
    if (data) {
      const mainProduct: IOption = {};
      const subProduct: IOption = {};
      data.subscriptions.forEach((it) => {
        mainProduct[it.parentId] = it.parentId;
        subProduct[it.id] = it.id;
      });
      setMainProduct(mainProduct);
      setSubProduct(subProduct);
    }
  }, [data]);

  const onSubmit = () => {
    const _productId = !Number.isNaN(Number(productId)) ? Number(productId) : 0;

    setErrors('');
    dispatch(
      AutologinByProduct.request({
        data: {
          productId: _productId,
          userId: data.absnum,
        },
        callBack: (succ, link) => {
          if (succ && link) {
            setLink(link);
          } else {
            setErrors('Сталася помилка');
          }
        },
      })
    );
  };

  const handleGenerateAutologin = () => {
    setErrors('');
    dispatch(
      Autologin.request({
        userId: data.absnum,
        callBack: (succ, link) => {
          if (succ && link) {
            setLink(link);
          } else {
            setErrors('Сталася помилка');
          }
        },
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner" style={{ minWidth: '300px' }}>
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h2>Увійти як клієнт</h2>
          <SelectSearchReq
            required
            title="Головний продукт"
            className={classes.textField}
            onChange={(data) => {
              setProductId('');
              selectedMainProduct(data);
            }}
            options={mainOptions}
            handleRequest={handleSearch}
          />
          <SelectOutline
            required
            id="outlined-basic"
            title="Версія продукту"
            value={productId}
            className={classes.textField}
            options={subOptions}
            handleChange={setProductId}
          />
          <div className={classes.link}>
            <a href={link} target="_blank" rel="noreferrer">
              {link.substring(0, 70)}
            </a>
            {link ? (
              <IconButton aria-label="copy" onClick={() => navigator.clipboard.writeText(link)}>
                <ContentCopyIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
        <div className={classes.boxFooter}>
          <Button color="primary" disabled={!productId || Users.loading} variant="contained" onClick={onSubmit}>
            Згенерувати посилання
          </Button>
          <Button color="primary" disabled={Users.loading} variant="contained" onClick={handleGenerateAutologin}>
            Логін на expertus.com.ua
          </Button>
          <Button color="inherit" variant="contained" onClick={handleClose}>
            скасувати
          </Button>
        </div>
        {errors ? (
          <Alert severity="error">
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Modal>
  );
};
