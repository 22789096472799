import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  value: string;
  data?: { key: string; value: string }[];
  handleApprove: (isApprove: boolean) => void;
}

export const WarningModal: React.FC<BoxModalProps> = ({ open, value, data, handleApprove }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => handleApprove(false)}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner" style={{ minWidth: '300px' }}>
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h2>Ви дійсно хочете видалити {value}?</h2>
          {data
            ? data.map((item, i) => (
                <div key={i} style={{ textAlign: 'left' }}>
                  <strong>{item.key}:</strong> {item.value}
                </div>
              ))
            : null}
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button color="error" variant="contained" onClick={() => handleApprove(true)}>
              так
            </Button>
          </div>
          <Button color="success" variant="contained" onClick={() => handleApprove(false)}>
            не видаляти
          </Button>
        </div>
      </div>
    </Modal>
  );
};
