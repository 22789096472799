import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import { ActionTypes, TSupportState, ISupportSub, ISupportQuest } from './types';

export const setListQuestions = (payload: TSupportState['listQuestions']) => action(ActionTypes.SET_LIST_QUESTIONS, payload);
export const setSupportSub = (payload: TSupportState['listSub']) => action(ActionTypes.SET_SUPPORT_SUB, payload);

const GetSupportAction = new Actions('GET_SUPPORT', ActionTypes);
export interface TypeGetSupportR extends TDefRequest {
  page?: number;
}
export const GetSupport = {
  request: (payload: TypeGetSupportR) => GetSupportAction.request(payload),
  success: (payload: TSupportState['data']) => GetSupportAction.success(payload || {}),
  error: (message: string) => GetSupportAction.error(message),
};

const CreateSupportAction = new Actions('CREATE_SUPPORT', ActionTypes);
export interface TypeCreateSupportR extends TDefRequest {
  data: FormData;
}
export const CreateSupport = {
  request: (payload: TypeCreateSupportR) => CreateSupportAction.request(payload),
  success: (payload: TSupportState['data']) => CreateSupportAction.success(payload || {}),
  error: (message: string) => CreateSupportAction.error(message),
};

const CreateSupportSubAction = new Actions('CREATE_SUPPORT_SUB', ActionTypes);
export interface TypeCreateSupportSubR extends TDefRequest {
  data: {
    partId: number;
    position: number;
    name: string;
    approved: number;
  };
}
export const CreateSupportSub = {
  request: (payload: TypeCreateSupportSubR) => CreateSupportSubAction.request(payload),
  success: (payload: ISupportSub) => CreateSupportSubAction.success(payload || {}),
  error: (message: string) => CreateSupportSubAction.error(message),
};

const CreateSupportQuestAction = new Actions('CREATE_SUPPORT_QUEST', ActionTypes);
export interface TypeCreateSupportQuestR extends TDefRequest {
  data: {
    partId: number;
    subpartId: number;
    name: string;
    body: string;
    approved: number;
    position: number;
  };
}
export const CreateSupportQuest = {
  request: (payload: TypeCreateSupportQuestR) => CreateSupportQuestAction.request(payload),
  success: (payload: ISupportQuest) => CreateSupportQuestAction.success(payload || {}),
  error: (message: string) => CreateSupportQuestAction.error(message),
};

const UpdateSupportAction = new Actions('UPDATE_SUPPORT', ActionTypes);
export interface TypeUpdateSupportR extends TDefRequest {
  id: number;
  data: FormData;
}
export const UpdateSupport = {
  request: (payload: TypeUpdateSupportR) => UpdateSupportAction.request(payload),
  success: (payload: TSupportState['data']) => UpdateSupportAction.success(payload || {}),
  error: (message: string) => UpdateSupportAction.error(message),
};

const UpdateSupportSubAction = new Actions('UPDATE_SUPPORT_SUB', ActionTypes);
export interface TypeUpdateSupportSubR extends TDefRequest {
  id: number;
  data: {
    partId: number;
    position: number;
    name: string;
    approved: number;
  };
}
export const UpdateSupportSub = {
  request: (payload: TypeUpdateSupportSubR) => UpdateSupportSubAction.request(payload),
  success: (payload: ISupportSub) => UpdateSupportSubAction.success(payload || {}),
  error: (message: string) => UpdateSupportSubAction.error(message),
};

const UpdateSupportQuestAction = new Actions('UPDATE_SUPPORT_QUEST', ActionTypes);
export interface TypeUpdateSupportQuestR extends TDefRequest {
  id: number;
  data: {
    partId: number;
    subpartId: number;
    name: string;
    body: string;
    approved: number;
    position: number;
  };
}
export const UpdateSupportQuest = {
  request: (payload: TypeUpdateSupportQuestR) => UpdateSupportQuestAction.request(payload),
  success: (payload: ISupportQuest) => UpdateSupportQuestAction.success(payload || {}),
  error: (message: string) => UpdateSupportQuestAction.error(message),
};

const DeleteSupportAction = new Actions('DELETE_SUPPORT', ActionTypes);
export interface TypeDeleteSupportR extends TDefRequest {
  id: number;
}
export const DeleteSupport = {
  request: (payload: TypeDeleteSupportR) => DeleteSupportAction.request(payload),
  success: (payload: TSupportState['data']) => DeleteSupportAction.success(payload || {}),
  error: (message: string) => DeleteSupportAction.error(message),
};

const DeleteSupportSubAction = new Actions('DELETE_SUPPORT_SUB', ActionTypes);
export interface TypeDeleteSupportSubR extends TDefRequest {
  id: number;
}
export const DeleteSupportSub = {
  request: (payload: TypeDeleteSupportSubR) => DeleteSupportSubAction.request(payload),
  success: (payload: ISupportSub) => DeleteSupportSubAction.success(payload || {}),
  error: (message: string) => DeleteSupportSubAction.error(message),
};

const DeleteSupportQuestAction = new Actions('DELETE_SUPPORT_QUEST', ActionTypes);
export interface TypeDeleteSupportQuestR extends TDefRequest {
  id: number;
}
export const DeleteSupportQuest = {
  request: (payload: TypeDeleteSupportQuestR) => DeleteSupportQuestAction.request(payload),
  success: (payload: ISupportQuest) => DeleteSupportQuestAction.success(payload || {}),
  error: (message: string) => DeleteSupportQuestAction.error(message),
};

const UpdateMainPositionsAction = new Actions('UPDATE_MAIN_POSITIONS', ActionTypes);
export interface TypeUpdateMainPositionsR extends TDefRequest {
  data: {
    positions: { id: number; position: number }[];
  };
}
export const UpdateMainPositions = {
  request: (payload: TypeUpdateMainPositionsR) => UpdateMainPositionsAction.request(payload),
  success: (payload: ISupportQuest) => UpdateMainPositionsAction.success(payload || {}),
  error: (message: string) => UpdateMainPositionsAction.error(message),
};

const UpdateSubPositionsAction = new Actions('UPDATE_SUB_POSITIONS', ActionTypes);
export interface TypeUpdateSubPositionsR extends TDefRequest {
  data: {
    partId: number;
    positions: { id: number; position: number }[];
  };
}
export const UpdateSubPositions = {
  request: (payload: TypeUpdateSubPositionsR) => UpdateSubPositionsAction.request(payload),
  success: (payload: { partId: number; subSupport: ISupportQuest[] }) => UpdateSubPositionsAction.success(payload || {}),
  error: (message: string) => UpdateSubPositionsAction.error(message),
};

const UpdateQuestPositionsAction = new Actions('UPDATE_QUEST_POSITIONS', ActionTypes);
export interface TypeUpdateQuestPositionsR extends TDefRequest {
  data: {
    partId: number;
    subpartId: number;
    positions: { id: number; position: number }[];
  };
}
export const UpdateQuestPositions = {
  request: (payload: TypeUpdateQuestPositionsR) => UpdateQuestPositionsAction.request(payload),
  success: (payload: { partId: number; subpartId: number; questions: ISupportQuest[] }) => UpdateQuestPositionsAction.success(payload || {}),
  error: (message: string) => UpdateQuestPositionsAction.error(message),
};
