import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { StylesEdit } from '../StylesEdit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ISupport } from '../../store/support/types';
import TextField from '@mui/material/TextField';
import { CreateSupport, UpdateSupport } from '../../store/support/actions';
import { UploadFile } from '../ui';

interface PropsType {
  type: 'edit' | 'new';
  position: number;
  data?: ISupport | null;
  handleCancel: () => void;
}

export const EditSupport: React.FC<PropsType> = ({ type, position, data, handleCancel }) => {
  const [name, setName] = React.useState(data?.name || '');
  const [description, setDescription] = React.useState(data?.description || '');
  const [img, setImg] = React.useState<File | string>(data?.img || '');
  const [approved, setApproved] = React.useState(data ? data.approved : 1);
  const [error, setError] = React.useState(false);

  const { Support } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const classes = StylesEdit();

  const onSubmit = () => {
    if (!name) return setError(true);
    setError(false);
    const callBack = (succ: boolean) => {
      if (succ) {
        handleCancel();
      }
    };

    const fd = new FormData();
    fd.append('name', name);
    fd.append('description', description);
    fd.append('img', img);
    fd.append('approved', String(approved));
    fd.append('position', String(position));

    if (type === 'new') {
      dispatch(
        CreateSupport.request({
          data: fd,
          callBack,
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateSupport.request({
          id: data.id,
          data: fd,
          callBack,
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.paper}>
        <Box className={classes.header}>
          <Typography component="h1" variant="h5">
            {type === 'new' ? 'Створити розділ' : 'Редагувати розділ'}
          </Typography>
        </Box>
        <FormControlLabel
          style={{ marginTop: '20px' }}
          control={<Checkbox checked={!!approved} />}
          onChange={() => setApproved(approved ? 0 : 1)}
          label="Публікувати"
        />
        <TextField
          style={{ marginTop: '20px' }}
          required
          size="small"
          id="outlined-basic-name"
          label="Назва"
          variant="outlined"
          value={name}
          error={error && !name}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />
        <TextField
          style={{ marginTop: '20px' }}
          size="small"
          id="outlined-basic-description"
          label="Опис"
          variant="outlined"
          value={description}
          className={classes.textField}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setDescription(event.target.value as string);
          }}
        />
        <UploadFile
          style={{ marginTop: '20px' }}
          isImg
          accept="image/*"
          id="photo"
          title="Картинка"
          hint="svg, png, jpeg"
          value={img}
          className={classes.textField}
          onChange={setImg}
        />
        <Box style={{ margin: '20px 0' }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Support.loading}
            onClick={onSubmit}
            style={{ height: '36px', marginRight: '40px' }}
          >
            {Support.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Support.errors ? (
          <Alert severity="error">
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
