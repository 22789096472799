import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  Autologin,
  AutologinByProduct,
  ChangePasswd,
  EmailConfirm,
  GetUser,
  GetUsers,
  SearchUsers,
  SendEmailConfirm,
  TypeAutologinByProductR,
  TypeAutologinR,
  TypeChangePasswdR,
  TypeEmailConfirmR,
  TypeGetUserR,
  TypeGetUsersR,
  TypeSearchUsersR,
  TypeSendEmailConfirmR,
} from './actions';
import ActionTypes, { TUsersState, IUser } from './types';

function* getUsersWorker(action: ReturnType<typeof GetUsers.request>): Generator {
  const { data, callBack } = action.payload as TypeGetUsersR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/users?${query}`,
    })) as TUsersState['data'];
    yield put(GetUsers.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUsers.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getUserWorker(action: ReturnType<typeof GetUser.request>): Generator {
  const { id, callBack } = action.payload as TypeGetUserR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/users/user/${id}`,
    })) as IUser;
    yield put(GetUser.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUser.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchUsersWorker(action: ReturnType<typeof SearchUsers.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchUsersR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/users?${query}`,
    })) as TUsersState['data'];
  } catch (e) {
    success = false;
    yield put(SearchUsers.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.users);
  }
}

function* changePasswordWorker(action: ReturnType<typeof ChangePasswd.request>): Generator {
  const { url , data, callBack } = action.payload as TypeChangePasswdR;

  let success = true;

  try {
    yield call(callApi, {
      method: 'patch',
      path: `/${url}/change-password`,
      data,
    });
    yield put(ChangePasswd.success());
  } catch (e) {
    success = false;
    yield put(ChangePasswd.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* sendEmailConfirmWorker(action: ReturnType<typeof SendEmailConfirm.request>): Generator {
  const { id, callBack } = action.payload as TypeSendEmailConfirmR;

  let success = true;

  try {
    yield call(callApi, {
      method: 'get',
      path: `/users/send-email-confirm/${id}`,
    });
    yield put(SendEmailConfirm.success());
  } catch (e) {
    success = false;
    yield put(SendEmailConfirm.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* emailConfirmWorker(action: ReturnType<typeof EmailConfirm.request>): Generator {
  const { id, callBack } = action.payload as TypeEmailConfirmR;

  let success = true;

  try {
    const user = (yield call(callApi, {
      method: 'get',
      path: `/users/email-confirm/${id}`,
    })) as IUser;
    yield put(EmailConfirm.success(user));
  } catch (e) {
    success = false;
    yield put(EmailConfirm.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* autologinWorker(action: ReturnType<typeof Autologin.request>): Generator {
  const { userId, callBack } = action.payload as TypeAutologinR;

  let success = true;
  let resp = { link: '' };
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/users/autologin/${userId}`,
    })) as { link: string };
    yield put(Autologin.success(resp.link));
  } catch (e) {
    success = false;
    yield put(Autologin.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp.link);
  }
}

function* autologinByProductWorker(action: ReturnType<typeof AutologinByProduct.request>): Generator {
  const { data, callBack } = action.payload as TypeAutologinByProductR;

  let success = true;
  let resp = { link: '' };
  try {
    resp = (yield call(callApi, {
      method: 'post',
      path: `/users/autologin/product`,
      data,
    })) as { link: string };
    yield put(AutologinByProduct.success(resp.link));
  } catch (e) {
    success = false;
    yield put(AutologinByProduct.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp.link);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_USERS_R, getUsersWorker);
  yield takeEvery(ActionTypes.GET_USER_R, getUserWorker);
  yield takeEvery(ActionTypes.SEARCH_USERS_R, searchUsersWorker);
  yield takeEvery(ActionTypes.CHANGE_PASSWORD_R, changePasswordWorker);
  yield takeEvery(ActionTypes.SEND_EMAIL_CONFIRM_R, sendEmailConfirmWorker);
  yield takeEvery(ActionTypes.EMAIL_CONFIRM_R, emailConfirmWorker);
  yield takeEvery(ActionTypes.AUTOLOGIN_R, autologinWorker);
  yield takeEvery(ActionTypes.AUTOLOGIN_BY_PRODUCT_R, autologinByProductWorker);
}

export default function* usersSaga() {
  yield all([fork(watchFetchRequest)]);
}
