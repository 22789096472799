import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { GetActions, TypeGetActionsR } from './actions';
import ActionTypes, { TActionsState } from './types';

function* getActionsWorker(action: ReturnType<typeof GetActions.request>): Generator {
  const { data, callBack } = action.payload as TypeGetActionsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/actions?${query}` : '/actions',
    })) as TActionsState['data'];
    yield put(GetActions.success(resp));
  } catch (e) {
    success = false;
    yield put(GetActions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_ACTIONS_R, getActionsWorker);
}

export default function* actionsSaga() {
  yield all([fork(watchFetchRequest)]);
}
