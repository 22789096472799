import React from 'react';

interface Props<T> {
  data?: T[];
  callback: (data: { id: number; position: number }[]) => void;
}

export const useDraggAndDrop = <T>({ data, callback }: Props<T>) => {
  const [list, setList] = React.useState<T[] | null>(null);
  const [draggId, setDraggId] = React.useState<number | null>(null);

  const ref = React.useRef<{ [x: string]: HTMLTableRowElement }>({});
  const refHoverId = React.useRef<{ [x: string]: number }>({});

  React.useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data]);

  const handleDraggEnd = React.useCallback(() => {
    if (!list || draggId === null || refHoverId.current['index'] === null || draggId === refHoverId.current['index']) return null;
    const currentCards = [...list];
    const card = currentCards[draggId];
    currentCards.splice(draggId, 1);
    currentCards.splice(refHoverId.current['index'], 0, card);
    setList(currentCards);
    setDraggId(null);

    const data = currentCards.map((it: any, i) => ({ id: it.id, position: i }));
    callback(data);
  }, [list, draggId, callback]);

  const getData = React.useCallback((current: HTMLTableRowElement | null) => {
    if (current) {
      const { position } = current.dataset;
      const num = Number(position);
      if (Number.isNaN(num)) {
        return null;
      }
      return num;
    }
    return null;
  }, []);

  const setCurrent = (ev: HTMLTableRowElement | null, position: number) => {
    if (ev) {
      ref.current[position] = ev;
      ref.current[position].ondrag = () => {
        if (draggId === null) {
          const index = getData(ref.current[position]);
          if (index !== null) {
            setDraggId(index);
          }
        }
      };
      ref.current[position].ondragover = () => {
        const index = getData(ref.current[position]);
        if (index !== null) {
          refHoverId.current['index'] = index;
          ref.current[position].style.background = '#554e4e70';
        }
      };
      ref.current[position].ondragleave = () => {
        const index = getData(ref.current[position]);
        if (index !== null) {
          ref.current[position].style.background = '';
        }
      };
      ref.current[position].ondragend = () => {
        const index = getData(ref.current[position]);
        if (index !== null) {
          handleDraggEnd();
        }
      };
    }
  };

  return {
    list,
    setCurrent,
  };
};
