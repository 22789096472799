import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { GetBookmarks, TypeGetBookmarksR } from './actions';
import ActionTypes, { TBookmarksState } from './types';

function* getBookmarksWorker(action: ReturnType<typeof GetBookmarks.request>): Generator {

  const { data, callBack,callBackFile } = action.payload as TypeGetBookmarksR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/bookmarks?${query}` : '/bookmarks',
    })) as TBookmarksState['data'];
    if (!callBackFile){
      yield put(GetBookmarks.success(resp));
    } else{
      yield call(callBackFile, true, resp);
    }
  } catch (e) {
    success = false;
    yield put(GetBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}
function* getBookmarksCsvWorker(action: ReturnType<typeof GetBookmarks.request>): Generator {

  const { data, callBack, } = action.payload as TypeGetBookmarksR;

  let success = true;
  const query = buildQuery(data);
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/bookmarkscsv?${query}` : '/bookmarkscsv',
    })) as [any];
    if (resp) {
      // Конвертація даних JSON у формат CSV з екрануванням коми
      const fields = Object.keys(resp[0]); // Отримання назв полів з першого об'єкта
      const csvData = [
        fields.join(','), // Заголовок CSV: назви полів, розділені комами
        ...resp.map((item) =>
          fields
            .map((field) =>
              typeof item[field] === 'string' && item[field].includes(',')
                ? `"${item[field].replace(/"/g, '""')}"` // Екранування коми подвійними лапками та подвоєння подвійних лапок в тексті
                : item[field]
            )
            .join(',')
        ), // Рядки CSV для кожного об'єкта
      ].join('\n');
    
      // Створення та викачування файлу CSV
      const downloadUrl = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'bookmarks.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
    
 
  } catch (e) {
    success = false;
    yield put(GetBookmarks.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_BOOKMARKS_R, getBookmarksWorker);
  yield takeEvery(ActionTypes.GET_BOOKMARKSCSV_R, getBookmarksCsvWorker);

}

export default function* bookmarksSaga() {
  yield all([fork(watchFetchRequest)]);
}
