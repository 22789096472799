import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TUsersState, IUser, IUserSubscription } from './types';

export const setSubscriptions = (payload: IUserSubscription[]) => action(ActionTypes.SET_SUBSCRIPTIONS, payload);
export const setEditData = (payload: TUsersState['editData']) => action(ActionTypes.SET_EDIT_DATA, payload);
export const setUserSubs = (payload: TUsersState['userSubs']) => action(ActionTypes.SET_USER_SUBSCRIPTIONS, payload);

const GetUsersAction = new Actions('GET_USERS', ActionTypes);
export interface TypeGetUsersR extends TDefRequest {
  data: {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    page?: number;
  };
}
export const GetUsers = {
  request: (payload: TypeGetUsersR) => GetUsersAction.request(payload),
  success: (payload: TUsersState['data']) => GetUsersAction.success(payload || {}),
  error: (message: string) => GetUsersAction.error(message),
};

const GetUserAction = new Actions('GET_USER', ActionTypes);
export interface TypeGetUserR extends TDefRequest {
  id: number;
}
export const GetUser = {
  request: (payload: TypeGetUserR) => GetUserAction.request(payload),
  success: (payload: IUser) => GetUserAction.success(payload || {}),
  error: (message: string) => GetUserAction.error(message),
};

const SearchUsersAction = new Actions('SEARCH_USERS', ActionTypes);
export interface TypeSearchUsersR extends TDefRequest {
  data: {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    page?: number;
  };
}
export const SearchUsers = {
  request: (payload: TypeSearchUsersR) => SearchUsersAction.request(payload),
  success: (payload: TUsersState['data']) => SearchUsersAction.success(payload || {}),
  error: (message: string) => SearchUsersAction.error(message),
};

const ChangePasswdAction = new Actions('CHANGE_PASSWORD', ActionTypes);
export interface TypeChangePasswdR extends TDefRequest {
  url:string,
  data: {
    password: string;
    id: number;
  };
}
export const ChangePasswd = {
  request: (payload: TypeChangePasswdR) => ChangePasswdAction.request(payload),
  success: () => ChangePasswdAction.success({}),
  error: (message: string) => ChangePasswdAction.error(message),
};

const SendEmailConfirmAction = new Actions('SEND_EMAIL_CONFIRM', ActionTypes);
export interface TypeSendEmailConfirmR extends TDefRequest {
  id: number;
}
export const SendEmailConfirm = {
  request: (payload: TypeSendEmailConfirmR) => SendEmailConfirmAction.request(payload),
  success: () => SendEmailConfirmAction.success({}),
  error: (message: string) => SendEmailConfirmAction.error(message),
};

const EmailConfirmAction = new Actions('EMAIL_CONFIRM', ActionTypes);
export interface TypeEmailConfirmR extends TDefRequest {
  id: number;
}
export const EmailConfirm = {
  request: (payload: TypeEmailConfirmR) => EmailConfirmAction.request(payload),
  success: (payload: IUser) => EmailConfirmAction.success(payload || {}),
  error: (message: string) => EmailConfirmAction.error(message),
};

const AutologinAction = new Actions('AUTOLOGIN', ActionTypes);
export interface TypeAutologinR extends TDefRequest {
  userId: number;
}
export const Autologin = {
  request: (payload: TypeAutologinR) => AutologinAction.request(payload),
  success: (payload: string) => AutologinAction.success(payload || {}),
  error: (message: string) => AutologinAction.error(message),
};

const AutologinByProductAction = new Actions('AUTOLOGIN_BY_PRODUCT', ActionTypes);
export interface TypeAutologinByProductR extends TDefRequest {
  data: {
    userId: number;
    productId: number;
  };
}
export const AutologinByProduct = {
  request: (payload: TypeAutologinByProductR) => AutologinByProductAction.request(payload),
  success: (payload: string) => AutologinByProductAction.success(payload || {}),
  error: (message: string) => AutologinByProductAction.error(message),
};
