import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  editorRef?: React.MutableRefObject<any | null>;
  initialValue?: string;
  placeholder?: string;
}

export const EditorDefault: React.FC<Props> = ({ editorRef, placeholder, initialValue }) => {
  const textConfig = {
    language: 'uk',
    height: 350,
    width: '900px',
    plugins: 'paste preview advcode link charmap',
    menubar: false,
    toolbar:
      'undo redo | code | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | charmap | link',
    paste_as_text: true,
    quickbars_selection_toolbar: false,
    placeholder,
  };
  return (
    <>
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      {/* @ts-ignore*/}
      <Editor
        onInit={(_evt, editor) => editorRef && (editorRef.current = editor)}
        apiKey="uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv"
        initialValue={initialValue}
        init={textConfig as any}
      />
    </>
  );
};
