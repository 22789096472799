import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateSupport,
  CreateSupportQuest,
  CreateSupportSub,
  DeleteSupport,
  DeleteSupportQuest,
  DeleteSupportSub,
  GetSupport,
  TypeCreateSupportQuestR,
  TypeCreateSupportR,
  TypeCreateSupportSubR,
  TypeDeleteSupportQuestR,
  TypeDeleteSupportR,
  TypeDeleteSupportSubR,
  TypeGetSupportR,
  TypeUpdateMainPositionsR,
  TypeUpdateQuestPositionsR,
  TypeUpdateSubPositionsR,
  TypeUpdateSupportQuestR,
  TypeUpdateSupportR,
  TypeUpdateSupportSubR,
  UpdateMainPositions,
  UpdateQuestPositions,
  UpdateSubPositions,
  UpdateSupport,
  UpdateSupportQuest,
  UpdateSupportSub,
} from './actions';
import { ActionTypes, TSupportState, ISupportSub, ISupportQuest } from './types';

function* getSupportWorker(action: ReturnType<typeof GetSupport.request>): Generator {
  const { page, callBack } = action.payload as TypeGetSupportR;
  let success = true;
  const query = buildQuery({ page });
  const path = query ? `/support?${query}` : '/support';
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path,
    })) as TSupportState['data'];
    yield put(GetSupport.success(resp));
  } catch (e) {
    success = false;
    yield put(GetSupport.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createSupportWorker(action: ReturnType<typeof CreateSupport.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateSupportR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: '/support',
      data,
      isFormData: true,
    })) as TSupportState['data'];
    yield put(CreateSupport.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateSupport.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createSupportSubWorker(action: ReturnType<typeof CreateSupportSub.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateSupportSubR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: '/support/sub',
      data,
    })) as ISupportSub;
    yield put(CreateSupportSub.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateSupportSub.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createSupportQuestWorker(action: ReturnType<typeof CreateSupportQuest.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateSupportQuestR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      path: '/support/question',
      data,
    })) as ISupportQuest;
    yield put(CreateSupportQuest.success(resp));
  } catch (e) {
    success = false;
    yield put(CreateSupportQuest.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSupportWorker(action: ReturnType<typeof UpdateSupport.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateSupportR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/${id}`,
      data,
      isFormData: true,
    })) as TSupportState['data'];
    yield put(UpdateSupport.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSupport.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSupportSubWorker(action: ReturnType<typeof UpdateSupportSub.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateSupportSubR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/sub/${id}`,
      data,
    })) as ISupportSub;
    yield put(UpdateSupportSub.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSupportSub.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSupportQuestWorker(action: ReturnType<typeof UpdateSupportQuest.request>): Generator {
  const { id, data, callBack } = action.payload as TypeUpdateSupportQuestR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/question/${id}`,
      data,
    })) as ISupportQuest;
    yield put(UpdateSupportQuest.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSupportQuest.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSupportWorker(action: ReturnType<typeof DeleteSupport.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSupportR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/support/${id}`,
    })) as TSupportState['data'];
    yield put(DeleteSupport.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSupport.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSupportSubWorker(action: ReturnType<typeof DeleteSupportSub.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSupportSubR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/support/sub/${id}`,
    })) as ISupportSub;
    yield put(DeleteSupportSub.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSupportSub.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteSupportQuestWorker(action: ReturnType<typeof DeleteSupportQuest.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteSupportQuestR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/support/question/${id}`,
    })) as ISupportQuest;
    yield put(DeleteSupportQuest.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteSupportQuest.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateMainPositionsWorker(action: ReturnType<typeof UpdateMainPositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateMainPositionsR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/positions/main`,
      data,
    })) as ISupportQuest;
    yield put(UpdateMainPositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateMainPositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSubPositionsWorker(action: ReturnType<typeof UpdateSubPositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateSubPositionsR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/positions/sub`,
      data,
    })) as { partId: number; subSupport: ISupportQuest[] };
    yield put(UpdateSubPositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSubPositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateQuestPositionsWorker(action: ReturnType<typeof UpdateQuestPositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateQuestPositionsR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/support/positions/question`,
      data,
    })) as { subpartId: number; partId: number; questions: ISupportQuest[] };
    yield put(UpdateQuestPositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateQuestPositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SUPPORT_R, getSupportWorker);
  yield takeEvery(ActionTypes.CREATE_SUPPORT_R, createSupportWorker);
  yield takeEvery(ActionTypes.CREATE_SUPPORT_SUB_R, createSupportSubWorker);
  yield takeEvery(ActionTypes.CREATE_SUPPORT_QUEST_R, createSupportQuestWorker);
  yield takeEvery(ActionTypes.UPDATE_SUPPORT_R, updateSupportWorker);
  yield takeEvery(ActionTypes.UPDATE_SUPPORT_SUB_R, updateSupportSubWorker);
  yield takeEvery(ActionTypes.UPDATE_SUPPORT_QUEST_R, updateSupportQuestWorker);
  yield takeEvery(ActionTypes.DELETE_SUPPORT_R, deleteSupportWorker);
  yield takeEvery(ActionTypes.DELETE_SUPPORT_SUB_R, deleteSupportSubWorker);
  yield takeEvery(ActionTypes.DELETE_SUPPORT_QUEST_R, deleteSupportQuestWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_POSITIONS_R, updateMainPositionsWorker);
  yield takeEvery(ActionTypes.UPDATE_SUB_POSITIONS_R, updateSubPositionsWorker);
  yield takeEvery(ActionTypes.UPDATE_QUEST_POSITIONS_R, updateQuestPositionsWorker);
}

export default function* supportSaga() {
  yield all([fork(watchFetchRequest)]);
}
