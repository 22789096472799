import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {buildQuery} from '../../utils/buildQuery';
import {callApi} from '../../utils/callApi';
import {
  GetScholl,
  TypeGetSchollR,
  TypeUpdateCertificateNovaPostR,
  TypeUpdateSchoolCertificateR,
  UpdateCertificateNPData,
  UpdateSchoolCertificate
} from './actions';
import ActionTypes, {IScholl, TSchollState} from './types';

function* getSchollWorker(action: ReturnType<typeof GetScholl.request>): Generator {

  const {data, callBack, callBackFile} = action.payload as TypeGetSchollR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/scholl?${query}` : '/scholl',
    })) as TSchollState['data'];
    if (!callBackFile) {
      yield put(GetScholl.success(resp));
    } else {
      yield call(callBackFile, true, resp);
    }
  } catch (e) {
    success = false;
    yield put(GetScholl.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getSchollCsvWorker(action: ReturnType<typeof GetScholl.request>): Generator {

  const {data, callBack,} = action.payload as TypeGetSchollR;

  let success = true;
  const query = buildQuery(data);
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: query ? `/schollcsv?${query}` : '/schollcsv',
      isBlobResponseType: true,
    })) as Blob;
    if (resp) {
      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `school.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    success = false;
    yield put(GetScholl.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSchoolCertificateWorker(action: ReturnType<typeof UpdateSchoolCertificate.request>): Generator {
  const {
    app_id,
    program_id,
    certificate_id,
    data,
    callBack
  } = action.payload as TypeUpdateSchoolCertificateR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      path: `/apps/${app_id}/programs/${program_id}/certificates/${certificate_id}`,
      data
    })) as IScholl;
    yield put(UpdateSchoolCertificate.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateSchoolCertificate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateCertificateNPDataWorker(action: ReturnType<typeof UpdateCertificateNPData.request>): Generator {
  const {
    app_id,
    program_id,
    certificate_id,
    data,
    callBack
  } = action.payload as TypeUpdateCertificateNovaPostR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      path: `/apps/${app_id}/programs/${program_id}/certificates/${certificate_id}/nova-post`,
      data
    })) as IScholl;
    yield put(UpdateCertificateNPData.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateCertificateNPData.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SCHOLL_R, getSchollWorker);
  yield takeEvery(ActionTypes.GET_SCHOLLCSV_R, getSchollCsvWorker);
  yield takeEvery(ActionTypes.UPDATE_SCHOOL_CERTIFICATE_R, updateSchoolCertificateWorker);
  yield takeEvery(ActionTypes.UPDATE_CERTIFICATE_NOVA_POST_R, updateCertificateNPDataWorker);
}

export default function* schollSaga() {
  yield all([fork(watchFetchRequest)]);
}
