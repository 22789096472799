export interface TAppsState {
  data: { apps: IApps[]; url: string; currentPage: number; pages: number, total : number;} | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IApps {
  absnum: number;
  name: string;
  url: string;
  main_product : string;
  main_product_id: string;
  productVersion: string;
  page: number;
}

enum AppsTypes {
  GET_ACTIONS_R = '@@apps/GET_ACTIONS_R',
  GET_ACTIONS_S = '@@apps/GET_ACTIONS_S',
  GET_ACTIONS_E = '@@apps/GET_ACTIONS_E',
}

export default AppsTypes;
