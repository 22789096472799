import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { StylesTable } from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { IProduct } from '../../store/products/types';
import { EnhancedTableHead } from '../ui';
import { Order, stableSort } from '../../utils/stableSort';
import { GetBookmarks, GetBookmarksCsv } from '../../store/bookmarks/actions';
import Button from '@mui/material/Button';
import { BasicDatePicker, SelectOutline, SelectSearchReq } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import { useSearchProducts } from '../../hooks/useSearchProducts';

interface Column {
  id: 'user_id' | 'email' | 'site' | 'title' | 'url' | 'date_create' | 'last_view_cabinet' |
  'count_click_cabinet' | 'count_click_widget' | 'last_view_widget' | 'date_delete';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;
const columns: Column[] = [
  { id: 'user_id', label: 'userid', align: 'center', sort, width: '5%' },
  { id: 'email', label: 'Email', align: 'center', sort, width: '10%' },
  { id: 'site', label: 'Назва продукта', align: 'center', sort, width: '7%' },
  { id: 'title', label: 'Назва матеріалу', align: 'center', sort, width: '10%' },
  { id: 'url', label: 'Url матеріалу', align: 'center', sort, width: '13%' },
  { id: 'date_create', label: 'Дата додавання', align: 'center', sort, width: '5%' },
  { id: 'last_view_cabinet', label: 'Дата останнього кліку в кабінеті', align: 'center', sort, width: '5%' },
  { id: 'count_click_cabinet', label: 'Кількість кліків у кабінеті', align: 'center', sort, width: '5%' },
  { id: 'count_click_widget', label: 'Дата останнього кліку у віджеті', align: 'center', sort, width: '5%' },
  { id: 'last_view_widget', label: 'Кількість кліків у віджеті', align: 'center', sort, width: '5%' },
  { id: 'date_delete', label: 'Дата видалення', align: 'center', sort, width: '5%' },

];

type PropsType = {};

export const ListBookmarks: React.FC<PropsType> = () => {
  const [user_id, setUser_id] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [dateStart, setDateStart] = React.useState<Date | null>(null);
  const [dateEnd, setDateEnd] = React.useState<Date | null>(null);
  const [productMain, setProductMain] = React.useState<IDataOpt | null>(null);
  const [productId, setProductId] = React.useState('');
  const [typeSubscribe, setTypeSubscribe] = React.useState('3');

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');

  const { Bookmarks } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const { subOptions, mainOptions, selectedMainProduct, handleSearch: handleSearchProduct } = useSearchProducts();

  React.useEffect(() => {
    dispatch(
      GetBookmarks.request({
        data: {
          user_id,
          email,
          url,
          dateStart,
          dateEnd,
          typeSubscribe,
          productMainId: productMain ? String(productMain.id) : '',
          productId
        },
      })
    );
  }, [dispatch, user_id, email, url, dateStart, dateEnd, productMain, productId, typeSubscribe]);

  const handleChangePage = (event: unknown, page: number) => {
    if (!Bookmarks.data) return null;
    if (Bookmarks.data.pages >= page) {
      dispatch(
        GetBookmarks.request({
          data: {
            user_id,
            email,
            url,
            dateStart,
            dateEnd,
            page,
            typeSubscribe,
            productMainId: productMain ? String(productMain.id) : '',
            productId
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setProductMain({ id: 0, label: '' });
    setProductId('');
    setUser_id('');
    setEmail('');
    setUrl('');
    setDateStart(null);
    setTypeSubscribe('');
    setDateEnd(null);
    dispatch(GetBookmarks.request({ data: {} }));
  };


  const exportFile = () => {
    dispatch(
      GetBookmarksCsv.request({
        data: {
          user_id,
          email,
          url,
          dateStart,
          dateEnd,
          typeSubscribe,
          productMainId: productMain ? String(productMain.id) : '',
          productId
        },
      })
    );
  };


  return (
    <>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="ID користувача"
          variant="outlined"
          value={user_id}
          className={classesTable.searchField}
          onChange={(e) => {
            setUser_id(e.target.value);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Email"
          variant="outlined"
          value={email}
          className={classesTable.searchField}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id"
          label="Назва (url) матеріалу"
          variant="outlined"
          value={url}
          className={classesTable.searchField}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        />
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker id="dateStart" label="Дата старту" value={dateStart} onChange={setDateStart}
          />
        </div>
        <div className={classesTable.searchField} style={{ width: '210px' }}>
          <BasicDatePicker id="dateEnd" label="Дата закінчення" value={dateEnd} onChange={setDateEnd} />
        </div>
        <SelectSearchReq
          data={productMain}
          style={{ marginRight: '10px', width: '210px' }}
          title="Головний продукт"
          className={classesTable.searchField}
          onChange={(data) => {
            setProductId('');
            selectedMainProduct(data);
            setProductMain(data);
          }}
          options={mainOptions}
          handleRequest={handleSearchProduct}
        />
        <SelectOutline
          style={{ marginRight: '10px', width: '210px' }}
          id="outlined-basic"
          title="Версія продукту"
          value={productId}
          className={classesTable.searchField}
          options={subOptions}
          handleChange={setProductId}
        />
           <SelectOutline
          style={{ width: '210px' }}
          className={classesTable.searchField}
          title="Тип підписки"
          options={[
            { value: '3', label: 'Усі' },
            { value: '1', label: 'Демодоступ' },
            { value: '0', label: 'Підписники' },
          ]}
          handleChange={(value) => setTypeSubscribe(value)}
          value={typeSubscribe}
        />
        <Button variant="contained" color="primary" onClick={clearFilters} style={{ height: '40px' }}>
          Очистити фільтри
        </Button>
        <Button variant="contained" color="primary" onClick={exportFile} style={{ height: '40px', marginLeft: '10px' }}>
          Вивантажити у CSV
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns} />
          <TableBody>
            {Bookmarks && Bookmarks.data &&
              stableSort(Bookmarks.data.bookmarks, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.user_id}`}>
                    <TableCell align="center">
                      {row.user_id}
                    </TableCell>
                    <TableCell align="center">
                      {row.email}
                    </TableCell>
                    <TableCell align="center">
                      {row.site}
                    </TableCell>
                    <TableCell align="center" style={{ overflowWrap: 'break-word' }}>
                      {row.title}
                    </TableCell>
                    <TableCell align="center" style={{ overflowWrap: 'break-word' }}>
                      {row.url}
                    </TableCell>
                    <TableCell align="center">
                      {row.date_create}
                    </TableCell>
                    <TableCell align="center">
                      {row.last_view_cabinet}
                    </TableCell>
                    <TableCell align="center">
                      {row.count_click_cabinet}
                    </TableCell>
                    <TableCell align="center">
                      {row.last_view_widget}
                    </TableCell>
                    <TableCell align="center">
                      {row.count_click_widget}
                    </TableCell>
                    <TableCell align="center">
                      {row.date_delete}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Bookmarks && Bookmarks.data ? Bookmarks.data.pages : 0}
        page={Bookmarks && Bookmarks.data ? Bookmarks.data.currentPage : 1}
        onChange={handleChangePage}
      />
    </>
  );
};
