export interface TSupportState {
  readonly data: { support: ISupport[]; currentPage: number; pages: number } | null;
  readonly listQuestions: { list: ISupportQuest[]; subpartId: number; name: string } | null;
  readonly listSub: { list: ISupportSub[]; partId: number; name: string } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface ISupport {
  id: number;
  name: string;
  description: string;
  img: string;
  position: number;
  approved: number;
  children: ISupportSub[];
}

export interface ISupportSub {
  id: number;
  partId: number;
  name: string;
  position: number;
  approved: number;
  questions: ISupportQuest[];
}

export interface ISupportQuest {
  id: number;
  partId: number;
  subpartId: number;
  name: string;
  body: string;
  position: number;
  approved: number;
}

export enum ActionTypes {
  SET_LIST_QUESTIONS = '@@support/SET_LIST_QUESTIONS',
  SET_SUPPORT_SUB = '@@support/SET_SUPPORT_SUB',

  GET_SUPPORT_R = '@@support/GET_SUPPORT_R',
  GET_SUPPORT_S = '@@support/GET_SUPPORT_S',
  GET_SUPPORT_E = '@@support/GET_SUPPORT_E',

  CREATE_SUPPORT_R = '@@support/CREATE_SUPPORT_R',
  CREATE_SUPPORT_S = '@@support/CREATE_SUPPORT_S',
  CREATE_SUPPORT_E = '@@support/CREATE_SUPPORT_E',

  CREATE_SUPPORT_SUB_R = '@@support/CREATE_SUPPORT_SUB_R',
  CREATE_SUPPORT_SUB_S = '@@support/CREATE_SUPPORT_SUB_S',
  CREATE_SUPPORT_SUB_E = '@@support/CREATE_SUPPORT_SUB_E',

  CREATE_SUPPORT_QUEST_R = '@@support/CREATE_SUPPORT_QUEST_R',
  CREATE_SUPPORT_QUEST_S = '@@support/CREATE_SUPPORT_QUEST_S',
  CREATE_SUPPORT_QUEST_E = '@@support/CREATE_SUPPORT_QUEST_E',

  UPDATE_SUPPORT_R = '@@support/UPDATE_SUPPORT_R',
  UPDATE_SUPPORT_S = '@@support/UPDATE_SUPPORT_S',
  UPDATE_SUPPORT_E = '@@support/UPDATE_SUPPORT_E',

  UPDATE_SUPPORT_SUB_R = '@@support/UPDATE_SUPPORT_SUB_R',
  UPDATE_SUPPORT_SUB_S = '@@support/UPDATE_SUPPORT_SUB_S',
  UPDATE_SUPPORT_SUB_E = '@@support/UPDATE_SUPPORT_SUB_E',

  UPDATE_SUPPORT_QUEST_R = '@@support/UPDATE_SUPPORT_QUEST_R',
  UPDATE_SUPPORT_QUEST_S = '@@support/UPDATE_SUPPORT_QUEST_S',
  UPDATE_SUPPORT_QUEST_E = '@@support/UPDATE_SUPPORT_QUEST_E',

  DELETE_SUPPORT_R = '@@support/DELETE_SUPPORT_R',
  DELETE_SUPPORT_S = '@@support/DELETE_SUPPORT_S',
  DELETE_SUPPORT_E = '@@support/DELETE_SUPPORT_E',

  DELETE_SUPPORT_SUB_R = '@@support/DELETE_SUPPORT_SUB_R',
  DELETE_SUPPORT_SUB_S = '@@support/DELETE_SUPPORT_SUB_S',
  DELETE_SUPPORT_SUB_E = '@@support/DELETE_SUPPORT_SUB_E',

  DELETE_SUPPORT_QUEST_R = '@@support/DELETE_SUPPORT_QUEST_R',
  DELETE_SUPPORT_QUEST_S = '@@support/DELETE_SUPPORT_QUEST_S',
  DELETE_SUPPORT_QUEST_E = '@@support/DELETE_SUPPORT_QUEST_E',

  UPDATE_MAIN_POSITIONS_R = '@@support/UPDATE_MAIN_POSITIONS_R',
  UPDATE_MAIN_POSITIONS_S = '@@support/UPDATE_MAIN_POSITIONS_S',
  UPDATE_MAIN_POSITIONS_E = '@@support/UPDATE_MAIN_POSITIONS_E',

  UPDATE_SUB_POSITIONS_R = '@@support/UPDATE_SUB_POSITIONS_R',
  UPDATE_SUB_POSITIONS_S = '@@support/UPDATE_SUB_POSITIONS_S',
  UPDATE_SUB_POSITIONS_E = '@@support/UPDATE_SUB_POSITIONS_E',

  UPDATE_QUEST_POSITIONS_R = '@@support/UPDATE_QUEST_POSITIONS_R',
  UPDATE_QUEST_POSITIONS_S = '@@support/UPDATE_QUEST_POSITIONS_S',
  UPDATE_QUEST_POSITIONS_E = '@@support/UPDATE_QUEST_POSITIONS_E',
}
