import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {

  DeleteUserAdmin,
  GetUsersAdmin,
  SearchUsers,
  UpdateUserAdmin,
  TypeDeleteUserAdminR,
  TypeGetUsersR,
  TypeSearchUsersR,
  TypeUpdateUserAdminR,
} from './actions';
import ActionTypes, { TUsersAdminState, IUserAdmin, IDeleteUserAdmin } from './types';

function* getUsersAdminWorker(action: ReturnType<typeof GetUsersAdmin.request>): Generator {
  const { data, callBack } = action.payload as TypeGetUsersR;
  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/usersAdmin?${query}`,
    })) as TUsersAdminState['data'];
    yield put(GetUsersAdmin.success(resp));
  } catch (e) {
    success = false;
    yield put(GetUsersAdmin.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}


function* searchUsersWorker(action: ReturnType<typeof SearchUsers.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchUsersR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/usersAdmin?${query}`,
    })) as TUsersAdminState['data'];
  } catch (e) {
    success = false;
    yield put(SearchUsers.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.users);
  }
}

function* updateUserWorker(action: ReturnType<typeof UpdateUserAdmin.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateUserAdminR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/usersAdmin/update`,
      data,
    })) as IUserAdmin;
    yield put(UpdateUserAdmin.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateUserAdmin.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}



function* deleteUserWorker(action: ReturnType<typeof DeleteUserAdmin.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteUserAdminR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/usersAdmin/${id}`,
    })) as IDeleteUserAdmin

    yield put(DeleteUserAdmin.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteUserAdmin.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_USERS_ADMIN_R, getUsersAdminWorker);
  yield takeEvery(ActionTypes.SEARCH_USERS_ADMIN_R, searchUsersWorker);
  yield takeEvery(ActionTypes.UPDATE_USER_ADMIN_R, updateUserWorker);
  yield takeEvery(ActionTypes.DELETE_USER_ADMIN_R, deleteUserWorker);

}

export default function* usersAdminSaga() {
  yield all([fork(watchFetchRequest)]);
}
