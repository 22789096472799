import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '2px solid #000',
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  value: string;
  handleApprove: (isApprove: boolean) => void;
}

export const WarningModalDate: React.FC<BoxModalProps> = ({ open, value, handleApprove }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => handleApprove(false)}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner" style={{ minWidth: '300px' }}>
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h2>Хочете змінити дату {value} з МИНУЛОЇ на ОБРАНУ ?</h2>
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button color="error" variant="contained" onClick={() => handleApprove(true)}>
              так
            </Button>
          </div>
          <Button color="success" variant="contained" onClick={() => handleApprove(false)}>
            ні
          </Button>
        </div>
      </div>
    </Modal>
  );
};
