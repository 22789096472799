import { Reducer } from 'redux';
import ActionTypes, { TProductActionsState } from './types';

export const initialState: TProductActionsState = {
  data: null,
  loading: false,
  errors: undefined,
  loadingFile: false,
  files: null,
  loadingFiles: false,
  downloadFile: false,
};

const reducer: Reducer<TProductActionsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTACTIONS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_PRODUCTACTIONS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_PRODUCTACTIONS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ActionTypes.GET_PRODUCTACTIONS_XLSX_R:
      return { ...state, loadingFile: true, errors: '' };

    case ActionTypes.GET_PRODUCTACTIONS_XLSX_E:
      return { ...state, loadingFile: false, errors: action.payload };

    case ActionTypes.GET_PRODUCTACTIONS_XLSX_S:
      return { ...state, loadingFile: false, errors: '' };

    case ActionTypes.GET_PRODUCTACTIONS_FILES_R:
      return { ...state, loadingFiles: true, errors: '' };

    case ActionTypes.GET_PRODUCTACTIONS_FILES_E:
      return { ...state, loadingFiles: false, errors: action.payload };

    case ActionTypes.GET_PRODUCTACTIONS_FILES_S:
      return {
        ...state,
        loadingFiles: false,
        files: action.payload,
      };

    case ActionTypes.GET_PRODUCTACTIONS_DOWNLOAD_FILE_R:
      return { ...state, downloadFile: true, errors: '' };

    case ActionTypes.GET_PRODUCTACTIONS_DOWNLOAD_FILE_E:
      return { ...state, downloadFile: false, errors: action.payload };

    case ActionTypes.GET_PRODUCTACTIONS_DOWNLOAD_FILE_S:
      return {
        ...state,
        downloadFile: false
      };

    default:
      return state;
  }
};

export { reducer as ProductActionsReducer };
