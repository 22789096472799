import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppStore} from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import {StylesTable} from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import {IProduct} from '../../store/products/types';
import {useSearch} from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import {BasicDatePicker, EnhancedTableHead} from '../ui';
import {Order, stableSort} from '../../utils/stableSort';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import {BoxModal} from '../modals';
import moment from 'moment';
import {
  GetProductActions,
  GetProductActionsFiles,
  GetProductActionsXLSX
} from '../../store/productActions/actions';
import Button from '@mui/material/Button';
import ModalFiles from './ModalFiles';
interface Column {
  id: 'userId' | 'appId' | 'actionId' | 'adate' | 'params' | 'source';
  label: string;
  sort?: boolean;
  align?: 'right' | 'left' | 'center';
  width?: string;
  roles?: number[];
}

const sort = true;
const columns: Column[] = [
  {id: 'userId', label: 'Userid', align: 'center', sort},
  {id: 'appId', label: 'AppID', align: 'center', sort},
  {id: 'actionId', label: 'ActionID', align: 'center', sort},
  {id: 'source', label: 'source', align: 'center', sort},
  {id: 'params', label: 'params', align: 'center', sort},
  {id: 'adate', label: 'Дата створення події', align: 'center', width: '10%', sort},
];

type PropsType = {};

export const ListProductActions: React.FC<PropsType> = () => {
  const [bitrixid, setBitrixid] = React.useState('');
  const [actionId, setActionId] = React.useState('');
  const [action_alias, setActionAlias] = React.useState('');

  const [source, setSource] = React.useState('');
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [isModal, setIsModal] = React.useState<{ value: string } | null>(null);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>('absnum');
  const {ProductActions} = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const {loading, handleSearch} = useSearch({
    SearchData: GetProductActions,
  });
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IProduct) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleChangeData<T>(value: T, query: string, callBack: (value: T) => void) {
    if (query === 'startDate' && !moment(value as unknown as string).isValid()) return null;
    if (query === 'endDate' && !moment(value as unknown as string).isValid()) return null;
    const data: any = {
      bitrixid,
      actionId,
      action_alias,
      source,
      startDate: moment(startDate).isValid() ? startDate : null,
      endDate: moment(endDate).isValid() ? endDate : null,
    };
    callBack(value);
    delete data[query];
    handleSearch(value as unknown as string, query, data);
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!ProductActions.data) return null;
    if (ProductActions.data.pages >= page) {
      dispatch(
        GetProductActions.request({
          data: {
            bitrixid,
            actionId,
            action_alias,
            source,
            startDate: startDate,
            endDate: endDate,
            page,
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setBitrixid('');
    setActionId('');
    setActionAlias('');
    setSource('');
    setStartDate(null);
    setEndDate(null);
    dispatch(GetProductActions.request({data: {}}));
  };

  const exportFile = () => {
    const data = {
      bitrixid,
      actionId,
      action_alias,
      source,
      startDate: startDate,
      endDate: endDate
    };
    dispatch(
      GetProductActionsXLSX.request({
        data,
      })
    )
  };
  const handleGenerateFiles = async () => {
    dispatch(
      GetProductActionsFiles.request({
        data: {},
      })
    );
  }
  //перевищує ліміт скачування
  const exceedsDownloadLimit = ProductActions.data ? ProductActions.data.total > 400000 : false;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    handleGenerateFiles();
  };

  return (
    <>
      <Box style={{display: 'flex', flexWrap: 'wrap'}}>
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="Userid"
          variant="outlined"
          value={bitrixid}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'bitrixid', setBitrixid);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id-2"
          label="actionId"
          variant="outlined"
          value={actionId}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'actionId', setActionId);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id-2"
          label="action_alias"
          variant="outlined"
          value={action_alias}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'action_alias', setActionAlias);
          }}
        />
        <TextField
          style={{marginRight: '10px'}}
          size="small"
          id="outlined-basic-id"
          label="Source"
          variant="outlined"
          value={source}
          className={classesTable.searchField}
          onChange={(e) => {
            handleChangeData(e.target.value, 'source', setSource);
          }}
        />
        <div className={classesTable.searchField} style={{width: '210px'}}>
          <BasicDatePicker
            id="dateStart"
            label="Дата старту"
            value={startDate}
            onChange={(value) => {
              handleChangeData(value, 'startDate', setStartDate);
            }}
          />
        </div>
        <div className={classesTable.searchField} style={{width: '210px'}}>
          <BasicDatePicker
            id="dateEnd"
            label="Дата закінчення"
            value={endDate}
            onChange={(value) => {
              handleChangeData(value, 'endDate', setEndDate);
            }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={clearFilters} style={{height: '40px'}}>
          Очистити фільтри
        </Button>
        <Button
          disabled={ProductActions.loadingFile || loading || exceedsDownloadLimit}
          variant="contained"
          color="primary"
          onClick={exportFile}
          style={{height: '40px', marginLeft: '10px'}}
        >
          {ProductActions.loadingFile ? <CircularProgress size={25}/> : null}
          {exceedsDownloadLimit ? (
            <Tooltip
              title='Максимельне число записів для вивантаження - 400 000. Застосуйте фільтри для зменшення кількості записів.'
            >
              <Box
                style={{
                  pointerEvents: 'all',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >Вивантажити у CSV <InfoIcon/></Box>
            </Tooltip>
          ) : 'Вивантажити у CSV'}
        </Button>
        <Button variant="contained" color="primary" onClick={handleOpen} style={{height: '40px', marginLeft: '10px'}}>
          Архів подій
          <ArchiveIcon style={{marginLeft: '10px'}}/>
        </Button>
        {ProductActions.data && ProductActions.data.total && ProductActions.data.total ? (
          <div style={{height: '40px'}}
               className={classesTable.textLength}>Знайдено {ProductActions.data.total} подій</div>
        ) : ''}
        {loading ? <CircularProgress size={25}/> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} columns={columns}/>
          <TableBody>
            {ProductActions.data &&
              stableSort(ProductActions.data.actions, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{maxHeight: 50}} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">
                      {row.userId}
                      <br/>
                      {row.email || ''}
                    </TableCell>
                    <TableCell align="center">
                      {row.appId}
                      <br/>
                      <IconButton aria-label="edit" onClick={() => setIsModal({value: row.source})}>
                        <RemoveRedEyeIcon/>
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {row.actionId}
                      <br/>
                      {row.action_alias}
                    </TableCell>
                    <TableCell align="center" style={{overflowWrap: 'break-word'}}>
                      {row.source}
                    </TableCell>
                    <TableCell align="center" style={{wordBreak: 'break-all'}}>
                      {row.param1 ? `param1: ${row.param1}` : ''}
                      <br/>
                      {row.param2 ? `param2: ${row.param2}` : ''}
                      <br/>
                      {row.param3 ? `param3: ${row.param3}` : ''}
                      <br/>
                      {row.param4 ? `param4: ${row.param4}` : ''}
                      <br/>
                      {row.param5 ? `param5: ${row.param5}` : ''}
                      <br/>
                      {row.param6 ? `param6: ${row.param6}` : ''}
                    </TableCell>
                    <TableCell align="center">{moment(row.adate).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={ProductActions.data ? ProductActions.data.pages : 0}
        page={ProductActions.data ? ProductActions.data.currentPage : 1}
        onChange={handleChangePage}
      />
      {isModal ? <BoxModal open={!!isModal} value={isModal.value} handleClose={() => setIsModal(null)}/> : null}
      <ModalFiles
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};