import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TUsersAdminState, IUserAdmin, IDeleteUserAdmin } from './types';

const GetUsersAdminAction = new Actions('GET_USERS_ADMIN', ActionTypes);
export interface TypeGetUsersR extends TDefRequest {
  data: {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    page?: number;
  };
}
export const GetUsersAdmin = {
  request: (payload: TypeGetUsersR) => GetUsersAdminAction.request(payload),
  success: (payload: TUsersAdminState['data']) => GetUsersAdminAction.success(payload || {}),
  error: (message: string) => GetUsersAdminAction.error(message),
};

const GetUserAction = new Actions('GET_USER_ADMIN', ActionTypes);
export interface TypeGetUserR extends TDefRequest {
  id: number;
}
export const GetUserAdmin = {
  request: (payload: TypeGetUserR) => GetUserAction.request(payload),
  success: (payload: IUserAdmin) => GetUserAction.success(payload || {}),
  error: (message: string) => GetUserAction.error(message),
};

const SearchUsersAction = new Actions('SEARCH_USERS', ActionTypes);
export interface TypeSearchUsersR extends TDefRequest {
  data: {
    id?: number;
    fio?: string;
    username?: string;
    adate?: string;
    role?: string;
  };
}
export const SearchUsers = {
  request: (payload: TypeSearchUsersR) => SearchUsersAction.request(payload),
  success: (payload: TUsersAdminState['data']) => SearchUsersAction.success(payload || {}),
  error: (message: string) => SearchUsersAction.error(message),
};

const UpdateUserAdminAction = new Actions('UPDATE_USER_ADMIN', ActionTypes);
export interface TypeUpdateUserAdminR extends TDefRequest {
  data: {
    username?: string;
    role?: string;
    password ? : string;
    fio ? : string;
    id: number;
  };
}
export const UpdateUserAdmin = {
  request: (payload: TypeUpdateUserAdminR) => UpdateUserAdminAction.request(payload),
  success: (payload: IUserAdmin) => UpdateUserAdminAction.success(payload || {}),
  error: (message: string) => UpdateUserAdminAction.error(message),
};

const DeleteUserAdminAction = new Actions('DELETE_USER_ADMIN', ActionTypes);
export interface TypeDeleteUserAdminR extends TDefRequest {
  id: number;
}
export const DeleteUserAdmin = {
  request: (payload: TypeDeleteUserAdminR) => DeleteUserAdminAction.request(payload),
  success: (payload: IDeleteUserAdmin) => DeleteUserAdminAction.success(payload || {}),
  error: (message: string) => DeleteUserAdminAction.error(message),
};