import { all, fork } from 'redux-saga/effects';

// function* handleGetUser(action: ReturnType<typeof getUserR>) {
//   try {
//     // const data: {} = yield call(callApi, 'post', '/auth');
//     // console.log('data: ', data);
//     yield put(getUserS({ id: 1 }));
//   } catch (e) {
//     yield put(getUserE(e));
//   }
// }

function* watchFetchRequest() {
  // yield takeEvery(ActionTypes.GET_USER_R, handleGetUser);
}

export default function* authSaga() {
  yield all([fork(watchFetchRequest)]);
}
