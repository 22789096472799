import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';
import { SelectOutline } from '../ui';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '2px solid #000',
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  initValue?: string;
  handleClose: () => void;
  handleConfirm: (value: string) => void;
}

export const ChangeStatus: React.FC<BoxModalProps> = ({ open, initValue, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(initValue || '');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner">
        <div className="boxBody">
          <h2>Змінити статус</h2>
          <SelectOutline
            options={[
              { value: '0', label: 'Створено' },
              { value: '1', label: 'Переданий' },
              { value: '2', label: 'Активовано' },
              { value: '3', label: 'Заблокований' },
              { value: '4', label: 'Деактивований' },
            ]}
            title="Статус"
            handleChange={setValue}
            value={value}
          />
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button color="primary" variant="contained" disabled={!value} onClick={() => value && handleConfirm(value)}>
              підтвердити
            </Button>
          </div>
          <Button color="inherit" variant="contained" onClick={handleClose} style={{ minWidth: '118px', height: '36px' }}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
