export interface TActionsState {
  data: { actions: IAction[]; currentPage: number; pages: number, total : number;} | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IAction {
  [x: string]: any;
  absnum: number;
  userId: number;
  email: string;
  appId: number;
  actionId: number;
  action: string;
  source: string;
  category1: string;
  category2: string;
  actionvalue: string;
  adate: Date;
  status: number;
}

enum ActionTypes {
  GET_ACTIONS_R = '@@actions/GET_ACTIONS_R',
  GET_ACTIONS_S = '@@actions/GET_ACTIONS_S',
  GET_ACTIONS_E = '@@actions/GET_ACTIONS_E',
}

export default ActionTypes;
