export const mergeFio = (data: { [x: string]: string | number } | null) => {
  if (!data) return '';
  return Object.keys(data)
    .map((key) => {
      if (data[key]) {
        return data[key];
      }
      return null;
    })
    .filter((it) => !!it)
    .join(' ');
};
