import { Reducer } from 'redux';
import { ActionTypes, ISupportQuest, ISupportSub, TSupportState } from './types';

export const initialState: TSupportState = {
  loading: true,
  data: null,
  listQuestions: null,
  listSub: null,
  errors: undefined,
};

const addQuestionsInSub = (list: ISupportSub[], question: ISupportQuest) => {
  return list.map((sub) => {
    if (sub.id === question.subpartId) {
      if (Array.isArray(sub.questions)) {
        return { ...sub, questions: [...sub.questions, question] };
      }
      return { ...sub, questions: [question] };
    }
    return sub;
  });
};

const updateQuestionsInSub = (list: ISupportSub[], question: ISupportQuest) => {
  return list.map((sub) => {
    if (sub.id === question.subpartId) {
      if (Array.isArray(sub.questions)) {
        return {
          ...sub,
          questions: sub.questions.map((quest) => {
            if (quest.id === question.id) {
              return question;
            }
            return quest;
          }),
        };
      }
      return { ...sub, questions: [question] };
    }
    return sub;
  });
};

const removeQuestionsInSub = (list: ISupportSub[], question: ISupportQuest) => {
  return list.map((sub) => {
    if (sub.id === question.subpartId) {
      if (Array.isArray(sub.questions)) {
        return {
          ...sub,
          questions: sub.questions.filter((quest) => quest.id !== question.id),
        };
      }
      return sub;
    }
    return sub;
  });
};

const reducer: Reducer<TSupportState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUPPORT_R:
    case ActionTypes.CREATE_SUPPORT_R:
    case ActionTypes.CREATE_SUPPORT_SUB_R:
    case ActionTypes.CREATE_SUPPORT_QUEST_R:
    case ActionTypes.UPDATE_SUPPORT_R:
    case ActionTypes.UPDATE_SUPPORT_SUB_R:
    case ActionTypes.UPDATE_SUPPORT_QUEST_R:
    case ActionTypes.DELETE_SUPPORT_R:
    case ActionTypes.DELETE_SUPPORT_SUB_R:
    case ActionTypes.DELETE_SUPPORT_QUEST_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_SUPPORT_E:
    case ActionTypes.CREATE_SUPPORT_E:
    case ActionTypes.CREATE_SUPPORT_SUB_E:
    case ActionTypes.CREATE_SUPPORT_QUEST_E:
    case ActionTypes.UPDATE_SUPPORT_E:
    case ActionTypes.UPDATE_SUPPORT_SUB_E:
    case ActionTypes.UPDATE_SUPPORT_QUEST_E:
    case ActionTypes.DELETE_SUPPORT_E:
    case ActionTypes.DELETE_SUPPORT_SUB_E:
    case ActionTypes.DELETE_SUPPORT_QUEST_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.SET_LIST_QUESTIONS:
      return { ...state, listQuestions: action.payload };

    case ActionTypes.SET_SUPPORT_SUB:
      return { ...state, listSub: action.payload };

    case ActionTypes.UPDATE_MAIN_POSITIONS_S:
    case ActionTypes.GET_SUPPORT_S:
      return { ...state, loading: false, data: action.payload };

    case ActionTypes.CREATE_SUPPORT_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? { ...state.data, support: [...state.data.support, action.payload] }
          : { support: [action.payload], currentPage: 1, pages: 1 },
      };

    case ActionTypes.CREATE_SUPPORT_SUB_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return { ...it, children: [...it.children, action.payload] };
                  }
                  return { ...it, children: [action.payload] };
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? { ...state.listSub, list: [...state.listSub.list, action.payload] }
            : state.listSub
          : state.listSub,
      };

    case ActionTypes.CREATE_SUPPORT_QUEST_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: addQuestionsInSub(it.children, action.payload),
                    };
                  }
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? { ...state.listSub, list: addQuestionsInSub(state.listSub.list, action.payload) }
            : state.listSub
          : state.listSub,

        listQuestions: state.listQuestions
          ? state.listQuestions.subpartId === action.payload.subpartId
            ? { ...state.listQuestions, list: [...state.listQuestions.list, action.payload] }
            : state.listQuestions
          : state.listQuestions,
      };

    case ActionTypes.UPDATE_SUPPORT_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.id) {
                  return action.payload;
                }
                return it;
              }),
            }
          : { support: [action.payload], currentPage: 1, pages: 1 },
      };

    case ActionTypes.UPDATE_SUPPORT_SUB_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: it.children.map((sub) => {
                        if (sub.id === action.payload.id) {
                          return action.payload;
                        }
                        return sub;
                      }),
                    };
                  }
                  return { ...it, children: [action.payload] };
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? {
                ...state.listSub,
                list: state.listSub.list.map((sub) => {
                  if (sub.id === action.payload.id) {
                    return action.payload;
                  }
                  return sub;
                }),
              }
            : state.listSub
          : state.listSub,
      };

    case ActionTypes.UPDATE_SUPPORT_QUEST_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: updateQuestionsInSub(it.children, action.payload),
                    };
                  }
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? {
                ...state.listSub,
                list: updateQuestionsInSub(state.listSub.list, action.payload),
              }
            : state.listSub
          : state.listSub,

        listQuestions: state.listQuestions
          ? state.listQuestions.subpartId === action.payload.subpartId
            ? {
                ...state.listQuestions,
                list: state.listQuestions.list.map((quest) => {
                  if (quest.id === action.payload.id) {
                    return action.payload;
                  }
                  return quest;
                }),
              }
            : state.listQuestions
          : state.listQuestions,
      };

    case ActionTypes.DELETE_SUPPORT_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.filter((it) => it.id !== action.payload.id),
            }
          : { support: [action.payload], currentPage: 1, pages: 1 },
      };

    case ActionTypes.DELETE_SUPPORT_SUB_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: it.children.filter((sub) => sub.id !== action.payload.id),
                    };
                  }
                  return { ...it, children: [action.payload] };
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? {
                ...state.listSub,
                list: state.listSub.list.filter((sub) => sub.id !== action.payload.id),
              }
            : state.listSub
          : state.listSub,
      };

    case ActionTypes.DELETE_SUPPORT_QUEST_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: removeQuestionsInSub(it.children, action.payload),
                    };
                  }
                }
                return it;
              }),
            }
          : state.data,

        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? {
                ...state.listSub,
                list: removeQuestionsInSub(state.listSub.list, action.payload),
              }
            : state.listSub
          : state.listSub,

        listQuestions: state.listQuestions
          ? state.listQuestions.subpartId === action.payload.subpartId
            ? {
                ...state.listQuestions,
                list: state.listQuestions.list.filter((quest) => quest.id !== action.payload.id),
              }
            : state.listQuestions
          : state.listQuestions,
      };

    case ActionTypes.UPDATE_SUB_POSITIONS_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId && Array.isArray(action.payload.subSupport)) {
                  return { ...it, children: action.payload.subSupport };
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId && Array.isArray(action.payload.subSupport)
            ? {
                ...state.listSub,
                list: action.payload.subSupport,
              }
            : state.listSub
          : state.listSub,
      };

    case ActionTypes.UPDATE_QUEST_POSITIONS_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              support: state.data.support.map((it) => {
                if (it.id === action.payload.partId) {
                  if (Array.isArray(it.children)) {
                    return {
                      ...it,
                      children: it.children.map((it) => {
                        if (it.id === action.payload.subpartId && Array.isArray(action.payload.questions)) {
                          return { ...it, questions: action.payload.questions };
                        }
                        return it;
                      }),
                    };
                  }
                }
                return it;
              }),
            }
          : state.data,
        listSub: state.listSub
          ? state.listSub.partId === action.payload.partId
            ? {
                ...state.listSub,
                list: state.listSub.list.map((it) => {
                  if (it.id === action.payload.subpartId && Array.isArray(action.payload.questions)) {
                    return { ...it, questions: action.payload.questions };
                  }
                  return it;
                }),
              }
            : state.listSub
          : state.listSub,

        listQuestions: state.listQuestions
          ? state.listQuestions.subpartId === action.payload.subpartId && Array.isArray(action.payload.questions)
            ? {
                ...state.listQuestions,
                list: action.payload.questions,
              }
            : state.listQuestions
          : state.listQuestions,
      };

    default:
      return state;
  }
};

export { reducer as SupportReducer };
