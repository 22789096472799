export interface TProductActionsState {
  data: { actions: IAction[]; currentPage: number; pages: number, total : number;} | null;
  files: YearFiles | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
  readonly loadingFile?: boolean;
  readonly loadingFiles?: boolean;
  readonly downloadFile?: boolean;
}

export interface IAction {
  absnum: number;
  userId: number;
  email: string;
  appId: number;
  actionId: number;
  action_alias: string;
  source: string;
  param1?: string;
  param2?: string;
  param3?: string;
  param4?: string;
  param5?: string;
  param6?: string;
  actionvalue: string;
  adate: Date;
  status: number;
}

interface FileInfo {
  fileName: string;
  filePath: string;
}

interface MonthFiles {
  [month: string]: FileInfo[];
}

export interface YearFiles {
  [year: string]: MonthFiles;
}

enum ActionTypes {
  GET_PRODUCTACTIONS_R = '@@actions/GET_PRODUCTACTIONS_R',
  GET_PRODUCTACTIONS_S = '@@actions/GET_PRODUCTACTIONS_S',
  GET_PRODUCTACTIONS_E = '@@actions/GET_PRODUCTACTIONS_E',

  GET_PRODUCTACTIONS_XLSX_R = '@@actions/GET_PRODUCTACTIONS_XLSX_R',
  GET_PRODUCTACTIONS_XLSX_S = '@@actions/GET_PRODUCTACTIONS_XLSX_S',
  GET_PRODUCTACTIONS_XLSX_E = '@@actions/GET_PRODUCTACTIONS_XLSX_E',

  GET_PRODUCTACTIONS_FILES_R = '@@actions/GET_PRODUCTACTIONS_FILES_R',
  GET_PRODUCTACTIONS_FILES_S = '@@actions/GET_PRODUCTACTIONS_FILES_S',
  GET_PRODUCTACTIONS_FILES_E = '@@actions/GET_PRODUCTACTIONS_FILES_E',

  GET_PRODUCTACTIONS_DOWNLOAD_FILE_R = '@@actions/GET_PRODUCTACTIONS_DOWNLOAD_FILE_R',
  GET_PRODUCTACTIONS_DOWNLOAD_FILE_S = '@@actions/GET_PRODUCTACTIONS_DOWNLOAD_FILE_S',
  GET_PRODUCTACTIONS_DOWNLOAD_FILE_E = '@@actions/GET_PRODUCTACTIONS_DOWNLOAD_FILE_E',
}

export default ActionTypes;
