import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListItemIcon from '@mui/material/ListItemIcon';
import { AppStore } from '../../store/applicationState';
import { setSideBar } from '../../store/sidebar/actions';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SchoolIcon from '@mui/icons-material/School';
import { drawerWidth } from '../../config';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import roles from '../../utils/roles';
import WorkIcon from '@mui/icons-material/Work';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CallToAppsIcon from '@mui/icons-material/Apps';

interface SidebarProps {}

const menu = [
  {
    icon: <WorkIcon fontSize="small" />,
    name: 'Усі продукти',
    page: 6,
    roles: [roles.MARKETER],
  },
  {
    icon: <BorderColorIcon fontSize="small" />,
    name: 'Підписки',
    page: 2,
    roles: [roles.MARKETER, roles.SUPPORT],
  },
  {
    icon: <PeopleAltIcon fontSize="small" />,
    name: 'Користувачі',
    page: 3,
    roles: [roles.MARKETER, roles.SUPPORT],
  },
  {
    icon: <QuestionMarkIcon fontSize="small" />,
    name: 'Допомога',
    page: 4,
    roles: [roles.SUPPORT],
  },
  {
    icon: <CallToActionIcon fontSize="small" />,
    name: 'Події',
    page: 7,
    roles: [roles.MARKETER],
  },
  {
    icon: <SchoolIcon fontSize="small" />,
    name: 'Школи',
    page: 12,
    roles: [roles.MARKETER, roles.SUPPORT],
  },
  {
    icon: <CallToAppsIcon fontSize="small" />,
    name: 'Appid',
    page: 8,
    roles: [roles.MARKETER],
  },
  {
    icon: <PeopleAltIcon fontSize="small" />,
    name: 'Адміністратори',
    page: 9,
    roles: [roles.ADMIN],
  },
  {
    icon: <CallToActionIcon  fontSize="small" />,
    name: 'Продуктові події',
    page: 10,
    roles: [roles.MARKETER],
  },
  {
    icon: <CallToActionIcon  fontSize="small" />,
    name: 'Закладки',
    page: 11,
    roles: [roles.MARKETER],
  },
];

export const Sidebar: React.FC<SidebarProps> = React.memo(() => {
  const { Auth, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Auth.data) {
      const page = menu.find((m) => Auth.data?.rights[roles.ADMIN] || !!m.roles.find((role) => !!Auth.data?.rights[role]));
      if (page) {
        dispatch(setSideBar({ value: page.page, name: page.name, type: 'list' }));
      }
    }
  }, [dispatch, Auth.data]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {menu
            .filter((m) => Auth.data?.rights[roles.ADMIN] || !!m.roles.find((role) => !!Auth.data?.rights[role]))
            .map((it, index) => (
              <ListItem key={index} disablePadding style={{ background: it.page === Sidebar.page.value ? 'rgba(0, 0, 0, 0.08)' : '' }}>
                <ListItemButton
                  onClick={() => {
                    dispatch(setSideBar({ value: it.page, name: it.name, type: Sidebar.page.type === 'main' ? 'list' : 'main' }));
                  }}
                >
                  <ListItemIcon>{it.icon}</ListItemIcon>
                  <ListItemText primary={it.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </Drawer>
  );
});
