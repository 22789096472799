import React, {useCallback, useEffect, useState} from "react";
import {IScholl} from "../../store/scholl/types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {StylesEdit} from "../StylesEdit";
import {callApi} from "../../utils/callApi";
import {Autocomplete} from "@mui/lab";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Button from "@mui/material/Button";
import {API_URL} from "../../config";
import {useDispatch} from "react-redux";
import {UpdateCertificateNPData} from "../../store/scholl/actions";

type PropsType = {
  data?: IScholl | null;
  handleCancel: () => void;
};

type NpCityType = {
  value: string;
  label: string;
}

type NpWarehouseType = {
  value: string;
  label: string;
}

const FormNP: React.FC<PropsType> = ({data, handleCancel}) => {
  const classes = StylesEdit();
  const [npCities, setNpCities] = useState<NpCityType[]>([]);
  const [npWarehouses, setNpWarehouses] = useState<any>([]);
  const [npDataLoading, setNpDataLoading] = useState<boolean>(false);
  const [npSelectedCity, setNpSelectedCity] = useState<NpCityType | null>(null);
  const [npSelectedWarehouse, setNpSelectedWarehouse] = useState<NpWarehouseType | null>(null);
  const [npRecipientPhone, setNpRecipientPhone] = useState<string>(data?.recipient_phone || '');
  const [npRecipientSecondName, setNpRecipientSecondName] = useState<string>(data?.recipient_second_name || '');
  const [npRecipientFirstName, setNpRecipientFirstName] = useState<string>(data?.recipient_first_name || '');
  const [npRecipientMiddleName, setNpRecipientMiddleName] = useState<string>(data?.recipient_middle_name || '');
  const [managerName, setManagerName] = useState<string>(data?.changed_NP_data_by || '');
  const isNotValidPhone = npRecipientPhone.length < 12;
  const dispatch = useDispatch();

  const isDisabled =
    !npRecipientPhone.trim() ||
    isNotValidPhone ||
    !npRecipientSecondName.trim() ||
    !npRecipientFirstName.trim() ||
    !npRecipientMiddleName.trim() ||
    !managerName.trim() ||
    !npSelectedCity ||
    !npSelectedWarehouse;

  const getNPWarehouses = useCallback((cityValue: string) => {
    setNpDataLoading(true);
    callApi({
      anotherDomain: true,
      path: `${API_URL}/new_post/cities/${cityValue}/warehouses`,
      method: 'get',
    }).then((res) => {
      setNpWarehouses(res);
      setNpSelectedWarehouse(res.find((warehouse: NpWarehouseType) => warehouse.value === data?.recipient_post) || null);
      setNpDataLoading(false);
    })
      .catch((err) => {
        setNpDataLoading(false);
        console.log('err', err);
      })
  }, [data]);

  const getNPCities = useCallback(() => {
    callApi({
      anotherDomain: true,
      path: `${API_URL}/new_post/cities`,
      method: 'get',
      data: {type: "cities"}
    }).then((res) => {
      setNpCities(res);
      if (data?.recipient_city) {
        getNPWarehouses(data?.recipient_city);
        setNpSelectedCity(res.find((city: NpCityType) => city.value === data?.recipient_city) || null);
      } else {
        setNpDataLoading(false);
      }
    })
      .catch((err) => {
        setNpDataLoading(false);
        console.log('err', err);
      })
  }, [data, getNPWarehouses]);

  useEffect(() => {
    setNpDataLoading(true);
    getNPCities();
  }, [getNPCities]);

  const handleCityChange = (
    _event: React.SyntheticEvent,
    city: NpCityType | null,
  ) => {
    if (!city) return;
    setNpSelectedCity(city);
    getNPWarehouses(city.value);
  };

  const handleWarehouseChange = (
    _event: React.SyntheticEvent,
    warehouse: NpWarehouseType | null,
  ) => {
    setNpSelectedWarehouse(warehouse);
  };
  const onSubmit = () => {
    if (isDisabled) {
      return;
    }
    dispatch(
      UpdateCertificateNPData.request({
        app_id: data?.appId || 0,
        program_id: data?.id_program || '0',
        certificate_id: data?.id || 0,
        data: {
          recipient_phone: npRecipientPhone,
          recipient_second_name: npRecipientSecondName,
          recipient_first_name: npRecipientFirstName,
          recipient_middle_name: npRecipientMiddleName,
          recipient_city: npSelectedCity?.value,
          recipient_post: npSelectedWarehouse?.value,
          changed_NP_data_by: managerName,
        },
        callBack: (success) => success && handleCancel(),
      })
    );
  }
  return (
    <>
      <Box style={{width: '50%', position: 'relative'}} className={classes.inputsWrapper}>
        <Typography component="h2" variant="h6">
          Дані НП для відправки сертифікату
        </Typography>
        <>
          <TextField
            required
            name={'secondName'}
            size="small"
            id="outlined-basic"
            label="Прізвище"
            variant="outlined"
            value={npRecipientSecondName}
            className={classes.textFieldAutoWidth}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNpRecipientSecondName(event.target.value as string);
            }}
          />
          <TextField
            required
            name={'firstName'}
            size="small"
            id="outlined-basic"
            label="Ім'я"
            variant="outlined"
            value={npRecipientFirstName}
            className={classes.textFieldAutoWidth}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNpRecipientFirstName(event.target.value as string);
            }}
          />
          <TextField
            required
            name={'middleName'}
            size="small"
            id="outlined-basic"
            label="По-батькові"
            variant="outlined"
            value={npRecipientMiddleName}
            className={classes.textFieldAutoWidth}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setNpRecipientMiddleName(event.target.value as string);
            }}
          />
          {/*@ts-ignore*/}
          <InputMask
            mask="+380 99 999 99 99"
            placeholder='+380 ХХ ХХХ ХХ ХХ'
            value={npRecipientPhone}
            disabled={false}
            maskChar=" "
            onChange={(data) => setNpRecipientPhone(data.target.value.replace(/\D/g, ''))}
          >
            {/*@ts-ignore*/}
            {(inputProps: any) => (
              <TextField
                size="small"
                id="outlined-basic"
                label="Телефон"
                variant="outlined"
                className={classes.textFieldAutoWidth}
                {...inputProps}
              />
            )}
          </InputMask>
          <Autocomplete
            className={classes.textFieldAutoWidth}
            id="combo-box-demo"
            size={'small'}
            getOptionLabel={(option) => option.label}
            options={npCities}
            renderInput={(params) => <TextField {...params} label="Населений пункт"/>}
            value={npSelectedCity}
            loading={npDataLoading}
            onChange={handleCityChange}
          />
          <Autocomplete
            className={classes.textFieldAutoWidth}
            id="combo-box-demo"
            size={'small'}
            getOptionLabel={(option) => option.label}
            options={npWarehouses}
            renderInput={(params) => <TextField {...params} label="Відділення/поштомат НП"/>}
            value={npSelectedWarehouse}
            loading={npDataLoading}
            onChange={handleWarehouseChange}
          />
          <TextField
            style={{marginTop: '40px'}}
            required
            name={'changedBy'}
            size="small"
            id="outlined-basic"
            label="ПІБ хто змінює дані"
            variant="outlined"
            value={managerName}
            className={classes.textFieldAutoWidth}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setManagerName(event.target.value as string);
            }}
          />
        </>
      </Box>
      <Box style={{margin: '20px 0'}}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isDisabled}
          onClick={onSubmit}
          style={{height: '36px', marginRight: '40px'}}
        >
          Зберегти
        </Button>
        <Button variant="outlined" className={classes.submit} onClick={handleCancel} style={{height: '36px'}}>
          Повернутися
        </Button>
      </Box>
    </>
  );
}

export default FormNP;