import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import React from "react";
import {IScholl} from "../../store/scholl/types";
import {useSelector} from "react-redux";
import {AppStore} from "../../store/applicationState";
import CircularProgress from '@mui/material/CircularProgress';
import FormNP from "./FormNP";
import FormUserData from "./FormUserData";

type PropsType = {
  data?: IScholl | null;
  handleCancel: () => void;
  isNPDataForm?: boolean;
};

export const EditRow: React.FC<PropsType> = ({data, handleCancel, isNPDataForm}) => {
  const {Scholl} = useSelector((store: AppStore) => store);

  return (
    <Box style={{position: 'relative'}}>
      {
        Scholl.loadingUpdatedItem &&
				<Box
					style={{
            position: 'absolute',
            top: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255, 255, 255, .7)',
            width: '100%',
            height: '100%',
            zIndex: 2
          }}
				>
					<CircularProgress size={40}/>
				</Box>
      }
      <CssBaseline/>
      <>
        {
          isNPDataForm ?
            <FormNP data={data} handleCancel={handleCancel}/>
            :
            <FormUserData data={data} handleCancel={handleCancel}/>
        }
      </>
    </Box>
  );
}