import {Reducer} from 'redux';
import ActionTypes, {TSchollState} from './types';

export const initialState: TSchollState = {
  data: null,
  loading: false,
  errors: undefined,
  dataUpdatedItem: null,
  loadingUpdatedItem: false,
  errorsUpdatedItem: undefined,
};

const reducer: Reducer<TSchollState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SCHOLL_R:
    case ActionTypes.GET_SCHOLLCSV_R:
      return {...state, errors: ''};
    case ActionTypes.UPDATE_SCHOOL_CERTIFICATE_R:
      return {...state, loadingUpdatedItem: true, errorsUpdatedItem: ''};
    case ActionTypes.UPDATE_CERTIFICATE_NOVA_POST_R:
      return {...state, loadingUpdatedItem: true, errorsUpdatedItem: ''};
    case ActionTypes.GET_SCHOLLCSV_E:
    case ActionTypes.GET_SCHOLL_E:
      return {...state, loading: false, errors: action.payload};
    case ActionTypes.UPDATE_SCHOOL_CERTIFICATE_E:
      return {...state, loadingUpdatedItem: false, errorsUpdatedItem: action.payload};
    case ActionTypes.UPDATE_CERTIFICATE_NOVA_POST_E:
      return {...state, loadingUpdatedItem: false, errorsUpdatedItem: action.payload};
    case ActionTypes.GET_SCHOLLCSV_S:
    case ActionTypes.GET_SCHOLL_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.UPDATE_SCHOOL_CERTIFICATE_S:
      return {
        ...state,
        loadingUpdatedItem: false,
        dataUpdatedItem: action.payload,
      };
    case ActionTypes.UPDATE_CERTIFICATE_NOVA_POST_S:
      return {
        ...state,
        loadingUpdatedItem: false,
        dataUpdatedItem: action.payload,
      };

    default:
      return state;
  }
};

export {reducer as SchollReducer};
