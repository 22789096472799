import React from "react";
import Modal from "@mui/material/Modal";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getMonthName} from '../../utils/getMonthName';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useSelector, useDispatch} from "react-redux";
import {AppStore} from "../../store/applicationState";
import {DownloadProductActionsFile} from "../../store/productActions/actions";

const useStyles = makeStyles(() => ({
  fileItem: {
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    color: 'gray',
    cursor: 'pointer',
    '&:hover': {
      color: '#1976d2',
    },
  },
  fileItemName: {
    width: 'calc(100% - 30px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 9
  },
  modalWrapper: {
    width: '70vw',
    height: '70vh',
    background: 'white',
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0 0 20px 20px',
    borderRadius: '8px',
  },
  buttonClose: {
    background: 'none',
    border: 'none',
    fontSize: '35px',
    cursor: 'pointer',
    color: 'gray',
    '&:hover': {
      color: '#f44336',
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '20px 20px 0 0',
    background: 'white',
    zIndex: 9,
    position: 'sticky',
    top: 0,
  },
  itemsWrapper: {
    height: 'calc(100% - 85px)',
    overflowY: 'scroll',
  }
}));

type PropsType = {
  open: boolean,
  handleClose: () => void,
};

const ModalFiles: React.FC<PropsType> = (
  {
    open,
    handleClose,
  }
) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const {ProductActions} = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDownloadFile = (filePath: string) => {
    dispatch(
      DownloadProductActionsFile.request({
        path: filePath,
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.modalWrapper}
      >
        <Box
          className={classes.header}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Згенеровані файли продуктових подій
          </Typography>
          <button
            onClick={handleClose}
            className={classes.buttonClose}
          >
            &times;
          </button>
        </Box>
        {(ProductActions.loadingFile || ProductActions.downloadFile) ?
          <Box className={classes.loader}>
            <CircularProgress size={25}/>
          </Box> : null
        }
        <Box className={classes.itemsWrapper}>
          {
            ProductActions.files && Object.keys(ProductActions.files).reverse().map((year) => {
              return (
                <Box key={year} >
                  {ProductActions.files && ProductActions.files[year] && Object.keys(ProductActions.files[year]).reverse().map((month) => {
                    return (
                      <Accordion key={month + year} expanded={expanded === month + year}
                                 onChange={handleChange(month + year)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon/>}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{width: '33%', flexShrink: 0}}>
                            {getMonthName(month)}
                          </Typography>
                          <Typography sx={{color: 'text.secondary'}}>{year}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            style={{display: 'flex', flexWrap: 'wrap', gap: '25px', justifyContent: 'space-between'}}
                          >
                            {ProductActions.files && ProductActions.files[year] && ProductActions.files[year][month].map((file) => {
                              return (
                                <Box
                                  className={classes.fileItem}
                                  onClick={() => handleDownloadFile(file.filePath)}
                                  key={file.filePath}
                                >
                                  <ArrowCircleDownIcon/>
                                  <span
                                    className={classes.fileItemName}
                                  >
                                    {file.fileName.split('.')[0]}
                                  </span>
                                </Box>
                              )
                            })}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </Box>
              )
            })
          }
        </Box>
      </Box>
    </Modal>
  )
};


export default ModalFiles;