export const URI = process.env.REACT_APP_URL ||
  'https://config-id3.exp.com.ua';
// 'http://localhost:3017';

export const API_URL = URI.trim() + '/api'; //
export const drawerWidth = 280;
export const TOKEN = {
  access: 'YWNjZXNzLdsfJKHJKIIII',
  refresh: 'dsgfkjkojIJHKJlkjIII',
};
