import { Reducer } from 'redux';
import ActionTypes, { TProductsState } from './types';

export const initialState: TProductsState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TProductsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PRODUCT_R:
    case ActionTypes.GET_PRODUCTS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.UPDATE_PRODUCT_E:
    case ActionTypes.GET_PRODUCTS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_PRODUCTS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ActionTypes.UPDATE_PRODUCT_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              products: state.data.products.map((product) => {
                if (product.absnum === action.payload.absnum) {
                  return { ...action.payload, children: product.children };
                }
                if (action.payload.parentId === product.absnum && product.children) {
                  return { ...product, children: product.children.map((ch) => (ch.absnum === action.payload.absnum ? action.payload : ch)) };
                }
                return product;
              }),
            }
          : state.data,
      };

    default:
      return state;
  }
};

export { reducer as ProductsReducer };
