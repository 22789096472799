const ERROR_EMAIL = 'Неправильно зазначено e-mail';
const ERROR_PASSW = 'Латиниця і цифри, без пробілів, мінімум 4 символи';
const ERROR_FIO = 'Українською, без пробілів, від 2 букв';
const ERROR_POSITION = 'Оберіть необхідну посаду';

const checkFio = (value: string) => {
  if (/[а-яієїґ']/.test(value) && value.length >= 2 && !value.includes(' ')) {
    return true;
  }
  return false;
};

const collectionValidate = {
  lastName: {
    error: ERROR_FIO,
    checkValue: checkFio,
  },
  firstName: {
    error: ERROR_FIO,
    checkValue: checkFio,
  },
  middleName: {
    error: ERROR_FIO,
    checkValue: checkFio,
  },
  email: {
    error: ERROR_EMAIL,
    checkValue: (value: string) => {
      const strEnd = value.substring(value.lastIndexOf('.')).replace(/[0-9]/g, '');
      // eslint-disable-next-line
      if (value.includes('@') && strEnd.length >= 2 && !value.includes(' ') && !RegExp(/[`~!#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/).test(value)) {
        return true;
      }
      return false;
    },
  },
  password: {
    error: ERROR_PASSW,
    checkValue: (value: string) => {
      const str = value?.replace(/[0-9]/g, '');
      const num = value?.replace(/[^\d.]/g, '');
      if (/^[a-zA-Z]+$/.test(str) && num.length > 0 && value.length >= 4 && !value.includes(' ')) {
        return true;
      }
      return false;
    },
  },
  position: {
    error: ERROR_POSITION,
    checkValue: (value: string, options?: string[]) => {
      const lowValue = value.toLowerCase();
      return options?.find((it) => it.toLowerCase() === lowValue) ? true : false;
    },
  },
};

export { collectionValidate };
