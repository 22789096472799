import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { EditUsers } from './EditUsers';
import TextField from '@mui/material/TextField';
import { StylesTable } from '../Stylestable';
import { GetUsers, setEditData, setUserSubs } from '../../store/users/actions';
import { IUser } from '../../store/users/types';
import { mergeFio } from '../../utils/mergeFio';
import { UserSubscriptions } from './UserSubscriptions';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSearch } from '../../hooks/useSearch';
import CircularProgress from '@mui/material/CircularProgress';
import roles from '../../utils/roles';
import { Order, stableSort } from '../../utils/stableSort';
import { EnhancedTableHead } from '../ui';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface Column {
  id: 'absnum' | 'fio' | 'email' | 'edit' | 'products' | 'type' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  sort?: boolean;
  roles?: number[];
}

const sort = true;

const columns: Column[] = [
  { id: 'absnum', label: 'ID', align: 'center', width: '7%', sort },
  { id: 'fio', label: 'ПІБ', align: 'left', sort },
  { id: 'email', label: 'Email', align: 'left', sort },
  { id: 'edit', label: 'Редагувати', align: 'center', width: '100px', roles: [roles.ADMIN, roles.SUPPORT] },
  { id: 'products', label: 'Продукти клієнта', align: 'center', width: '120px' },
  { id: 'type', label: 'Тип клієнта', align: 'center', width: '90px' },
];

type PropsType = {};

export const ListUsers: React.FC<PropsType> = () => {
  const [bitrixid, setBitrixid] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [middleName, setMiddleName] = React.useState('');

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IUser>('absnum');

  const { Users, Auth, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { loading, handleSearch } = useSearch({
    SearchData: GetUsers,
  });

  React.useEffect(() => {
    if (Sidebar.page.type) {
      dispatch(setEditData(null));
      dispatch(setUserSubs(null));
    }
  }, [dispatch, Sidebar.page]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUser) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, page: number) => {
    if (!Users.data) return null;
    if (Users.data.pages >= page) {
      dispatch(
        GetUsers.request({
          data: {
            page,
            lastName,
            firstName,
            middleName,
          },
        })
      );
    }
  };

  const clearFilters = () => {
    setBitrixid('');
    setEmail('');
    setMiddleName('');
    setLastName('');
    setFirstName('');
    dispatch(
      GetUsers.request({
        data: {},
      })
    );
  };

  if (Users.editData) {
    return <EditUsers type={Users.editData.type} data={Users.editData.data} handleCancel={() => dispatch(setEditData(null))} />;
  }

  if (Users.userSubs) {
    return <UserSubscriptions data={Users.userSubs} handleCancel={() => dispatch(setUserSubs(null))} />;
  }

  return (
    <>
      <Box> 
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-bitrixid"
          label="Пошук по ID"
          variant="outlined"
          value={bitrixid}
          className={classesTable.searchField}
          onChange={(e) => {
            setBitrixid(e.target.value);
            handleSearch(e.target.value, 'id');
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-email"
          label="Email"
          variant="outlined"
          value={email}
          className={classesTable.searchField}
          onChange={(e) => {
            setEmail(e.target.value);
            handleSearch(e.target.value, 'email');
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-id-2"
          label="Прізвище"
          variant="outlined"
          value={lastName}
          className={classesTable.searchField}
          onChange={(e) => {
            setLastName(e.target.value);
            handleSearch(e.target.value, 'lastName', { firstName, middleName });
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-firstName"
          label="Ім'я"
          variant="outlined"
          value={firstName}
          className={classesTable.searchField}
          onChange={(e) => {
            setFirstName(e.target.value);
            handleSearch(e.target.value, 'firstName', { lastName, middleName });
          }}
        />
        <TextField
          style={{ marginRight: '10px' }}
          size="small"
          id="outlined-basic-middleName"
          label="По батькові"
          variant="outlined"
          value={middleName}
          className={classesTable.searchField}
          onChange={(e) => {
            setMiddleName(e.target.value);
            handleSearch(e.target.value, 'middleName', { lastName, firstName });
          }}
        />
        <Button variant="contained" color="primary" onClick={clearFilters} style={{ height: '36px' }}>
          Очистити фільтри
        </Button>
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <EnhancedTableHead order={order} orderBy={orderBy} columns={columns} rights={Auth.data?.rights} onRequestSort={handleRequestSort} />
          <TableBody>
            {Users.data &&
              stableSort(Users.data.users, order, orderBy).map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role="checkbox" tabIndex={-1} key={`${i}-${row.absnum}`}>
                    <TableCell align="center">{row.absnum}</TableCell>
                    <TableCell className={classesTable.row}>{mergeFio({ l: row.lname, f: row.fname, m: row.mname })}</TableCell>
                    <TableCell className={classesTable.row}>{row.email}</TableCell>
                    {Auth.data?.rights[roles.ADMIN] || Auth.data?.rights[roles.SUPPORT] ? (
                      <TableCell align="center">
                        <IconButton aria-label="edit" onClick={() => dispatch(setEditData({ type: 'edit', data: row }))}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    ) : null}
                    <TableCell align="center">
                      <Button variant="contained" color="primary" onClick={() => dispatch(setUserSubs(row))}>
                        Перейти
                      </Button>
                    </TableCell>
                    <TableCell align="center">{row.subscriptions?.length > 0 ? <AddIcon color="action" /> : <RemoveIcon color="action" />}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Users.data ? Users.data.pages : 0}
        page={Users.data ? Users.data.currentPage : 1}
        onChange={handleChangePage}
      />
    </>
  );
};
