import { Reducer } from 'redux';
import ActionTypes, { TActionsState } from './types';

export const initialState: TActionsState = {
  data: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TActionsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTIONS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_ACTIONS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_ACTIONS_S:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export { reducer as ActionsReducer };
