import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  value: string;
  handleClose: () => void;
}

export const BoxModal: React.FC<BoxModalProps> = ({ value, open, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner">
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h3 style={{ wordBreak: 'break-all' }}>{<a href={ value } target="_blank" rel="noreferrer">{value}</a>}</h3>
        </div>
        <div className="boxFooter">
          <Button variant="contained" onClick={handleClose} style={{ minWidth: '118px', height: '36px' }}>
            Закрити
          </Button>
        </div>
      </div>
    </Modal>
  );
};
