import { makeStyles } from '@mui/styles';

export const StylesEdit = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 15px 0 7px',
    flex: 1,
  },
  avatar: {
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '550px', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  textField: {
    marginTop: '20px !important',
    width: '100%',
  },
  textFieldAutoWidth: {
    marginTop: '20px !important',
    width: 'calc(100% - 20px)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '900px',
    alignItems: 'center',
  },
  headerItem: {
    marginRight: '30px',
  },
  wrappPhoto: {
    maxWidth: '300px',
  },
  photo: {
    color: ' -webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    maxWidth: '75px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  errMesg: {
    position: 'absolute',
    top: '40px',
    color: 'red',
    zIndex: 1000,
    fontSize: '12px',
  },
  inputsWrapper: {
    minWidth: '400px'
  }
}));
