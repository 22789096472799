import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import './BoxModal.scss';
import { StylesEdit } from '../StylesEdit';
import { UpdateSubscription } from '../../store/subscriptions/actions';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchUsers } from '../../hooks/useSearchUsers';
import { AppStore } from '../../store/applicationState';
import { ISubscription } from '../../store/subscriptions/types';
import { mergeFio } from '../../utils/mergeFio';
import { SelectSearchReq } from '../ui';
import { IDataOpt } from '../ui/SelectSearchReq';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '2px solid #000',
  },
  textField: {
    width: '100%',
  },
}));

interface BoxModalProps {
  open: boolean;
  data: ISubscription;
  handleClose: () => void;
}

export const ChangeUser: React.FC<BoxModalProps> = ({ open, data, handleClose }) => {
  const [user, setUser] = React.useState<IDataOpt | null>(
    data.userId ? { id: data.userId, label: `, ${mergeFio({ l: data.lname, f: data.fname, m: data.mname })}` } : null
  );
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const { Subscriptions } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { options, handleSearch } = useSearchUsers();

  const classes = useStyles();
  const classes2 = StylesEdit();

  const onSubmit = () => {
    if (!user) return setError(true);

    setError(false);
    dispatch(
      UpdateSubscription.request({
        data: {
          ukd: data.code,
          userId: user.id,
          status: data.status,
        },
        callBack: (succ) => {
          if (succ) {
            handleClose();
          }
          if (!succ) {
            setErrors('Сталася помилка');
          }
        },
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="boxModal-inner">
        <div className="boxBody">
          <h2>Змінити користувача</h2>
          <SelectSearchReq
            required
            title="ID Клієнта"
            value={user ? String(user.id) : ''}
            className={classes2.textField}
            onChange={setUser}
            options={options}
            error={error && !user}
            handleRequest={handleSearch}
          />
          <SelectSearchReq
            required
            title="ПІБ"
            value={user ? user.label.split(', ')[1] : ''}
            className={classes2.textField}
            onChange={setUser}
            options={options}
            error={error && !user}
            handleRequest={handleSearch}
          />
        </div>
        <div className="boxFooter">
          <Button
            variant="contained"
            color="primary"
            className={classes2.submit}
            disabled={Subscriptions.loading}
            onClick={onSubmit}
            style={{ height: '36px', marginRight: '40px' }}
          >
            {Subscriptions.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="outlined" className={classes2.submit} onClick={handleClose} style={{ height: '36px' }}>
            Повернутися
          </Button>
        </div>
        {error ? (
          <Alert severity="error">
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity="error">
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Modal>
  );
};
