import {Actions, TDefRequest} from '../Actions';
import SchollTypes, {IScholl, TSchollState} from './types';

const GetSchollAction = new Actions('GET_SCHOLL', SchollTypes);
const GetSchollCsvAction = new Actions('GET_SCHOLLCSV', SchollTypes);
const UpdateSchoolCertificateAction = new Actions('UPDATE_SCHOOL_CERTIFICATE', SchollTypes);
const UpdateCertificateNPDataAction = new Actions('UPDATE_CERTIFICATE_NOVA_POST', SchollTypes);

export interface TypeGetSchollR extends TDefRequest {
  data: {
    userId?: string,
    fio?: string,
    ukd?: string,
    productVersionName?: string,
    fioCertificate?: string,
    ukdDateStart?: Date | null,
    ukdDateEnd?: Date | null,
    dateTest?: Date | null,
    programType?: string;
    printed?: string;
    productMainId?: {};
    productId?: string;
    page?: number;
  };
}

export interface TypeUpdateSchoolCertificateR extends TDefRequest {
  app_id: number;
  program_id: string;
  certificate_id: number;
  data: {
    changed_by: string;
    changed_first_name: string;
    changed_second_name: string;
    changed_middle_name: string;
  };
}

export interface TypeUpdateCertificateNovaPostR extends TDefRequest {
  app_id: number;
  program_id: string;
  certificate_id: number;
  data: {
    changed_NP_data_by: string;
    recipient_second_name?: string;
    recipient_first_name?: string;
    recipient_middle_name?: string;
    recipient_phone?: string;
    recipient_city?: string;
    recipient_city_name?: string;
    recipient_post?: string;
  };
}

export const GetScholl = {
  request: (payload: TypeGetSchollR) => GetSchollAction.request(payload),
  success: (payload: TSchollState['data']) => GetSchollAction.success(payload || {}),
  error: (message: string) => GetSchollAction.error(message),
};
export const GetSchollCsv = {
  request: (payload: {}) => GetSchollCsvAction.request(payload),
  success: (payload: TSchollState['data']) => GetSchollCsvAction.success(payload || {}),
  error: (message: string) => GetSchollCsvAction.error(message),
};

export const UpdateSchoolCertificate = {
  request: (payload: TypeUpdateSchoolCertificateR) => UpdateSchoolCertificateAction.request(payload),
  success: (payload: IScholl) => UpdateSchoolCertificateAction.success(payload || {}),
  error: (message: string) => UpdateSchoolCertificateAction.error(message),
};

export const UpdateCertificateNPData = {
  request: (payload: TypeUpdateCertificateNovaPostR) => UpdateCertificateNPDataAction.request(payload),
  success: (payload: IScholl) => UpdateCertificateNPDataAction.success(payload || {}),
  error: (message: string) => UpdateCertificateNPDataAction.error(message),
};