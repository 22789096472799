export const ROW_PAGE = 50;
export const TIMEOUT_REQ = 1000;

export const collectionStatus: any = {
  '0': { name: 'Створено', color: 'blue' },
  '1': { name: 'Переданий', color: 'gray' },
  '2': { name: 'Активовано', color: 'green' },
  '3': { name: 'Заблокований', color: 'red' },
  '4': { name: 'Деактивований', color: 'orange' },
};

export type TTypeSubscriptions = {
  value: string;
  label: string;
};

export const typeSubscriptions: TTypeSubscriptions[] = [
  {value: '0', label: 'e_subscriber' },
  {value: '1', label: 's_subscriber' },
  {value: '2', label: 'p_subscriber' },
  {value: '3', label: 'sch_subscriber' }
]
