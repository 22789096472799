import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TActionsState } from './types';

const GetActionsAction = new Actions('GET_ACTIONS', ActionTypes);

export interface TypeGetActionsR extends TDefRequest {
  data: {
    bitrixid?: string;
    email?: string;
    source?: string;
    category1?: string;
    category2?: string;
    actionId?: string;
    adate?: Date | null;
    page?: number;
  };
}

export const GetActions = {
  request: (payload: TypeGetActionsR) => GetActionsAction.request(payload),
  success: (payload: TActionsState['data']) => GetActionsAction.success(payload || {}),
  error: (message: string) => GetActionsAction.error(message),
};
