export const ThrowError = {
  status: 403,
  detail: 'Some Error',
  init: function initFn() {
    const err = {
      status: this.status,
      detail: this.detail,
    };

    throw err;
  },
};
