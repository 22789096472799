import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { setSubscriptions } from '../users/actions';
import { IUserSubscription } from '../users/types';
import { GetSubscriptions, TypeGetSubscriptionsR, TypeUpdateSubscriptionR, UpdateSubscription } from './actions';
import ActionTypes, { ISubscription, TSubscriptionsState } from './types';

function* getSubscriptionsWorker(action: ReturnType<typeof GetSubscriptions.request>): Generator {
  const { data, callBack } = action.payload as TypeGetSubscriptionsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/subscriptions?${query}`,
    })) as TSubscriptionsState['data'];
    yield put(GetSubscriptions.success(resp));
  } catch (e) {
    success = false;
    yield put(GetSubscriptions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateSubscriptionWorker(action: ReturnType<typeof UpdateSubscription.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdateSubscriptionR;

  let success = true;

  try {
    const resp = (yield call(callApi, {
      method: 'patch',
      path: `/subscriptions`,
      data,
    })) as { codeToProduct: ISubscription; subscriptions: IUserSubscription[] };
    yield put(UpdateSubscription.success(resp.codeToProduct));
    yield put(setSubscriptions(resp.subscriptions));
  } catch (e) {
    success = false;
    yield put(UpdateSubscription.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SUBSCRIPTIONS_R, getSubscriptionsWorker);
  yield takeEvery(ActionTypes.UPDATE_SUBSCRIPTION_R, updateSubscriptionWorker);
}

export default function* subscriptionsSaga() {
  yield all([fork(watchFetchRequest)]);
}
