import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { StylesTable } from '../Stylestable';
import Pagination from '@mui/material/Pagination';
import { WarningModal } from '../modals';
import Button from '@mui/material/Button';
import { ISupport } from '../../store/support/types';
import { DeleteSupport, GetSupport, setSupportSub, UpdateMainPositions } from '../../store/support/actions';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { EditSupport } from './EditSupport';
import { ListSupportSub } from './SupportSub/ListSupportSub';
import { useDraggAndDrop } from '../../hooks/useDraggAndDrop';

interface Column {
  id: 'id' | 'name' | 'approved' | 'description' | 'delete' | 'edit' | 'sub';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'description', label: 'Опис', align: 'left', width: '10%' },
  { id: 'approved', label: 'Публікація', align: 'center', width: '9%' },
  { id: 'sub', label: 'Підрозділи', align: 'center', width: '10%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {};

export const ListSupport: React.FC<PropsType> = () => {
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: ISupport | null; position: number } | null>(null);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);

  const { Support } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { setCurrent, list } = useDraggAndDrop({
    data: Support.data?.support,
    callback: (data) => {
      dispatch(
        UpdateMainPositions.request({
          data: {
            positions: data,
          },
        })
      );
    },
  });

  const handleRemove = (id: number) => {
    if (!id) return null;
    dispatch(
      DeleteSupport.request({
        id,
      })
    );
  };

  const handleChangePage = (event: unknown, page: number) => {
    if (!Support.data) return null;
    if (Support.data.pages >= page) {
      dispatch(
        GetSupport.request({
          page,
        })
      );
    }
  };

  if (editData) {
    return <EditSupport type={editData.type} data={editData.data} position={editData.position} handleCancel={() => setEditData(null)} />;
  }

  if (Support.listSub) {
    return (
      <ListSupportSub
        name={Support.listSub.name}
        partId={Support.listSub.partId}
        listSupportSub={Support.listSub.list}
        handleCancel={() => dispatch(setSupportSub(null))}
      />
    );
  }

  return (
    <>
      <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Button
          style={{ margin: '0 20px 10px 7px' }}
          variant="contained"
          color="primary"
          onClick={() => setEditData({ type: 'new', position: Support.data?.support.length || 0 })}
        >
          Створити розділ
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((row, i) => {
              return (
                <TableRow
                  data-position={i}
                  draggable
                  ref={(e) => setCurrent(e, i)}
                  style={{ maxHeight: 50, cursor: 'move' }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${i}-${row.id}`}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell className={classesTable.row}>{row.description}</TableCell>
                  <TableCell align="center" className={classesTable.row}>
                    {row.approved ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => dispatch(setSupportSub({ list: row.children, partId: row.id, name: row.name }))}
                    >
                      Переглянути
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="edit" onClick={() => setEditData({ type: 'edit', data: row, position: row.position })}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="delete" onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classesTable.pagination}
        count={Support.data ? Support.data.pages : 0}
        page={Support.data ? Support.data.currentPage : 1}
        onChange={handleChangePage}
      />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            if (isApprove) {
              handleRemove(isModal.id);
            }
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
