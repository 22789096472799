import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { SearchProducts } from '../store/products/actions';
import { IProduct } from '../store/products/types';

export const useSearchProducts = (includeMain?: { [x: string]: number }, includeSub?: { [x: string]: number }) => {
  const [mainOptions, setMainOptions] = React.useState<IDataOpt[]>([]);
  const [subOptions, setSubOptions] = React.useState<{ value: string; label: string }[]>([]);
  const [products, setProducts] = React.useState<IProduct[]>([]);

  const { Products } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Products.data) {
      setProducts(Products.data.products);
      if (includeMain) {
        const opt = Products.data.products.filter((it) => !!includeMain[it.absnum]).map((it) => ({ id: it.absnum, label: it.name }));
        setMainOptions(opt);
      } else {
        const opt = Products.data.products.map((it) => ({ id: it.absnum, label: it.name }));
        setMainOptions(opt);
      }
    }
  }, [Products.data, includeMain]);

  const handleSearch = (value: string, callBack?: (resData: IDataOpt[]) => void) => {
    dispatch(
      SearchProducts.request({
        data: {
          name: value,
        },
        callBack: (succ, data) => {
          if (succ && Array.isArray(data)) {
            setProducts(data);
            if (callBack && includeMain) {
              const opt = data.filter((it: IProduct) => !!includeMain[it.absnum]).map((it: IProduct) => ({ id: it.absnum, label: it.name }));
              callBack(opt);
            } else if (callBack) {
              const opt = data.map((it: IProduct) => ({ id: it.absnum, label: it.name }));
              callBack(opt);
            }
          } else {
            callBack && callBack(mainOptions);
          }
        },
      })
    );
  };

  const selectedMainProduct = React.useCallback(
    (data: IDataOpt | null) => {
      if (products?.length > 0 && data) {
        const mainProduct = products.find((it) => it.absnum === data.id);
        if (mainProduct && includeSub) {
          const subProducts = mainProduct.children
            .filter((it: IProduct) => !!includeSub[it.absnum])
            .map((it: IProduct) => ({ value: String(it.absnum), label: it.name }));
          setSubOptions(subProducts);
        } else if (mainProduct) {
          const subProducts = mainProduct.children.map((it: IProduct) => ({ value: String(it.absnum), label: it.name }));
          setSubOptions(subProducts);
        }
      }
    },
    [products, includeSub]
  );

  return {
    mainOptions,
    subOptions,
    selectedMainProduct,
    handleSearch,
  };
};
