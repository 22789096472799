import { makeStyles } from '@mui/styles';

export const StylesTable = makeStyles(() => ({
  container: {
    padding: '0 7px 0 7px',
    maxHeight: 'calc(100vh - 220px)',
  },
  table: {
    tableLayout: 'fixed',
  },
  row: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '10px',
  },
  searchField: {
    marginRight: '10px !important',
    marginBottom: '10px !important',
  },
  rowText: {
    color: 'gray',
    fontWeight: 400,
  },
  textLength: {
    height: '40px',
    padding: '7px',
    margin: '0 0 0 10px'

  }
}));
