import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { Home, Login } from './pages';
import { GetActions } from './store/actions/actions';
import { AppStore } from './store/applicationState';
import { setAuth, setUser } from './store/auth/actions';
import { GetProducts } from './store/products/actions';
import { GetSubscriptions } from './store/subscriptions/actions';
import { GetApps } from './store/apps/actions';
import { GetProductActions } from './store/productActions/actions';
import { GetSupport } from './store/support/actions';
import { GetUsers } from './store/users/actions';
import { GetUsersAdmin } from './store/usersAdmin/actions';
import { GetBookmarks } from './store/bookmarks/actions';
import { GetScholl } from './store/scholl/actions';

import { checkToken } from './utils/checkToken';
import roles from './utils/roles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    loaderWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  })
);

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const { Auth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    const callBack = (isOk: boolean) => {
      dispatch(setAuth(isOk));
      setIsLoading(false);
    };

    (async () => {
      setIsLoading(true);
      const tokenData = await checkToken();
      if (tokenData.success) {
        dispatch(
          setUser({
            exp: tokenData.exp!,
            fio: tokenData.fio!,
            token: tokenData.token!,
            type: tokenData.type!,
            username: tokenData.username!,
            rights: tokenData.rights!,
            iat: tokenData.iat!,
            id: tokenData.id!,
          })
        );
        callBack(true);
      } else {
        setIsLoading(false);
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    if (Auth.isAuth) {
      dispatch(GetUsers.request({ data: {} }));
      dispatch(GetUsersAdmin.request({ data: {} }));
      dispatch(GetSubscriptions.request({ data: {} }));
      dispatch(GetApps.request({ data: {} }));
      dispatch(GetProducts.request({ data: {} }));
      dispatch(GetActions.request({ data: {} }));
      dispatch(GetProductActions.request({ data: {} }));
      dispatch(GetBookmarks.request({ data: {} }));
      dispatch(GetScholl.request({ data: {} }));
      if (!Auth.data?.rights[roles.MARKETER]) {
        dispatch(GetSupport.request({}));
      }
    }
  }, [Auth.isAuth, Auth.data?.rights, dispatch]);

  if (isLoading)
    return (
      <div className={classes.loaderWrapper}>
        <CircularProgress color="secondary" />
      </div>
    );
  if (Auth.isAuth) {
    return <Home />;
  }
  return <Login />;
}

export default App;
