export interface TAuth {
  exp: number;
  fio: string;
  token: string;
  type: string;
  username: string;
  rights: { [x: number]: number };
  iat: number;
  id: number;
}
export interface TAuthState {
  readonly data: TAuth | null;
  readonly loading: boolean;
  isAuth: boolean;
  readonly errors?: string | undefined;
}

export enum ActionTypes {
  GET_USER_R = '@@auth/GET_USER_R',
  GET_USER_S = '@@auth/GET_USER_S',
  GET_USER_E = '@@auth/GET_USER_E',

  SET_IS_AUTH = '@@auth/SET_IS_AUTH',
  SET_USER = '@@auth/SET_USER',

  USER_CLEAN_UP = '@@auth/USER_CLEAN_UP',
}
