export const debounce = (callback: (data: string, query: string, options?: { [x: string]: string }) => void, delay: number) => {
  let data = '';
  return (value: string, query: string, options?: { [x: string]: string }) => {
    data = value;
    let timeout = setTimeout(() => {
      let localData = value;
      if (data === localData) callback(data, query, options);
      clearTimeout(timeout);
    }, delay);
  };
};
