import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { StylesTable } from '../../Stylestable';
import { WarningModal } from '../../modals';
import Button from '@mui/material/Button';
import { ISupportSub } from '../../../store/support/types';
import { DeleteSupportSub, setListQuestions, UpdateSubPositions } from '../../../store/support/actions';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { EditSupportSub } from './EditSupportSub';
import { ListQuestion } from '../SupportQuest/ListQuestion';
import { AppStore } from '../../../store/applicationState';
import { useDraggAndDrop } from '../../../hooks/useDraggAndDrop';
import Typography from '@mui/material/Typography';

interface Column {
  id: 'id' | 'name' | 'approved' | 'delete' | 'edit' | 'questions';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'approved', label: 'Публікація', align: 'center', width: '9%' },
  { id: 'questions', label: 'Питання', align: 'center', width: '10%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {
  listSupportSub: ISupportSub[];
  partId: number;
  name: string;
  handleCancel: () => void;
};

export const ListSupportSub: React.FC<PropsType> = ({ name, listSupportSub, partId, handleCancel }) => {
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: ISupportSub | null; position: number } | null>(null);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);

  const { Support } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const classesTable = StylesTable();

  const { setCurrent, list } = useDraggAndDrop({
    data: listSupportSub,
    callback: (data) => {
      dispatch(
        UpdateSubPositions.request({
          data: {
            partId,
            positions: data,
          },
        })
      );
    },
  });

  const handleRemove = (id: number) => {
    dispatch(
      DeleteSupportSub.request({
        id,
      })
    );
  };

  if (editData) {
    return (
      <EditSupportSub partId={partId} type={editData.type} data={editData.data} position={editData.position} handleCancel={() => setEditData(null)} />
    );
  }

  if (Support.listQuestions) {
    return (
      <ListQuestion
        name={Support.listQuestions.name}
        partId={partId}
        subpartId={Support.listQuestions.subpartId}
        listQuestion={Support.listQuestions.list}
        handleCancel={() => dispatch(setListQuestions(null))}
      />
    );
  }

  return (
    <>
      <Box>
        <Typography component="h1" variant="h5" style={{ margin: '0 7px 20px 7px' }}>
          Підрозділи: {`№ ${partId}, ${name}`}
        </Typography>
        <Button
          style={{ margin: '0 20px 10px 7px' }}
          variant="contained"
          color="primary"
          onClick={() => setEditData({ type: 'new', position: listSupportSub.length || 0 })}
        >
          Створити підрозділ
        </Button>
        <Button style={{ margin: '0 20px 10px 7px' }} variant="outlined" color="primary" onClick={handleCancel}>
          Повернутися
        </Button>
      </Box>
      <TableContainer className={classesTable.container}>
        <Table className={classesTable.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((row, i) => {
              return (
                <TableRow
                  data-position={i}
                  draggable
                  ref={(e) => setCurrent(e, i)}
                  style={{ maxHeight: 50, cursor: 'move' }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${i}-${row.id}`}
                >
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell className={classesTable.row}>{row.name}</TableCell>
                  <TableCell align="center" className={classesTable.row}>
                    {row.approved ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => dispatch(setListQuestions({ list: row.questions, subpartId: row.id, name: row.name }))}
                    >
                      Переглянути
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="edit" onClick={() => setEditData({ type: 'edit', data: row, position: row.position })}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton aria-label="delete" onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
